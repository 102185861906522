<template>
  <div class="map-main-interface" @mousewheel="onMousewheel" @keydown="onKeydown" @keyup="onKeyup">
    <div :class="{ 'map-view-scroll-guide': true, visible: isInvalidScroll, hidden: !isInvalidScroll }">Use Ctrl + scroll to zoom the map</div>
    <!-- Map -->
    <Map :currentLog="mapViewSelectedLog"></Map>
    <!-- Filter button -->
    <v-btn :class="{ 'btn-filter': true, filtered: isFiltered }" height="40" ripple rounded width="110" @click="onClickFilterBtn()">
      <img v-if="!isFiltered" src="@/assets/Filter_Line_16px_Icon.svg" width="16px" />
      <img v-else src="@/assets/Filter_Line_16px_Icon-red.svg" width="16px" />
      <div :class="{ 'btn-filter-text': true, filtered: isFiltered }">Filter</div>
    </v-btn>
    <!-- legend -->
    <div :class="`legend-map-view ${themeName}`">
      <MapDetailLegend />
    </div>
    <!-- Response Status popover-->
    <DetectionTypeResponseStatusPopover
      v-if="showMapViewResponseStatusPopover"
      @click-detection-type-edit-btn="onEmitClickDetectionTypeEditBtn"
      @click-edit-status-btn="onEmitClickEditStatusBtn"
    />
    <!-- <DetectionTypeResponseStatusPopover
      v-if="showMapViewResponseStatusPopover"
      @click-detection-type-edit-btn="showEditDetectionTypeUnavailableDialog = true"
      @click-edit-status-btn="onEmitClickEditStatusBtn"
    /> -->
    <EditDetectionTypeDialog
      :value="showEditDetectionTypeDialog"
      :selectedLogInfo="mapViewSelectedLog"
      :loadingForRepetitiveModal="loadingForRepetitiveModal"
      @close-detection-type-edit="onCloseDetectionTypeEditDialog"
    />
    <EditDetectionTypeUnavailableDialog
      :value="showEditDetectionTypeUnavailableDialog"
      @close-detection-type-edit-unavailable="onCloseDetectionTypeEditUnavailableDialog"
    />
    <EditStatusDialog
      :value="showEditStatusDialog"
      :selectedLogInfo="mapViewSelectedLog"
      :responseStatus="responseStatus"
      @close-status-edit="onCloseEditStatusDialog"
    ></EditStatusDialog>
    <FilterDialog :value="showFilterDialog" @close-filter-dialog="onCloseFilterDialog" @onFilterCheck="onFilterCheck"></FilterDialog>
    <SnackBar :value="snackBarOpen" :color="'secondary'" :message="'Detection Type Edited.'" @input="snackBarOpen = false" />
    <RepetitiveSelectModal
      v-if="showRepetitiveSelectModal"
      :value="showRepetitiveSelectModal"
      :selectedFirstDetectionLog="mapViewSelectedLog"
      :firstDetectionsWithIn24Hours="firstDetectionsWithIn24Hours"
      @close="onCloseRepetitiveSelectModal"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import Theme from '@/mixin/theme.mixin'
import Util from '@/util'

import { alert } from '@/api'
import DetectionTypeResponseStatusPopover from '@desktop/adminOrViewer/mapView/DetectionResponseStatusPopover.vue'
import Map from '@common/ArcGIS/MapViewMap'
import EditStatusDialog from '@desktop/dialog/EditStatusDialog.vue'
import EditDetectionTypeDialog from '@desktop/dialog/EditDetectionTypeDialog.vue'
import EditDetectionTypeUnavailableDialog from '@desktop/dialog/EditDetectionTypeUnavailableDialog.vue'
import FilterDialog from '@desktop/dialog/FilterDialog.vue'
import MapDetailLegend from '@common/MapDetailLegend.vue'
import SnackBar from '@common/SnackBar'
import RepetitiveSelectModal from './RepetitiveSelectModal'

export default {
  mixins: [Theme],
  watch: {
    mapViewSelectedLog(next, prev) {
      if (next && prev && next.log_id !== prev.log_id) {
        this.showFilterDialog = false
        this.showEditStatusDialog = false
        this.$store.dispatch('setShowMapViewResponseStatusPopoverAction', false)
        this.showEditDetectionTypeDialog = false
        this.showEditDetectionTypeUnavailableDialog = false
      }
    },
  },
  components: {
    DetectionTypeResponseStatusPopover,
    EditDetectionTypeDialog,
    EditDetectionTypeUnavailableDialog,
    Map,
    EditStatusDialog,
    FilterDialog,
    MapDetailLegend,
    SnackBar,
    RepetitiveSelectModal,
  },
  computed: {
    ...mapState(['showMapViewResponseStatusPopover', 'mapViewSelectedLog', 'recentLogList', 'mapviewExpandedAccordionLogId']),
    responseStatus() {
      if (!this.mapViewSelectedLog) return ''
      if (Util.isDuplicateLog(this.mapViewSelectedLog)) {
        return 'Repetitive'
      }
      let result = 'Alerted'
      if (this.mapViewSelectedLog.response_status?.is_validated) {
        result = 'Validated'
      }
      if (this.mapViewSelectedLog.response_status?.is_responded) {
        result = 'Responded'
      }
      return result
    },
  },
  data() {
    return {
      legendKeys: [
        {
          borderColor: '#F9423A73',
          color: '#F9423A',
          text: '3 Hours',
        },
        {
          borderColor: '#FFB80073',
          color: '#FFB800',
          text: '6 Hours',
        },
        {
          borderColor: '#384CFF73',
          color: '#384CFF',
          text: '24 Hours',
        },
        {
          borderColor: '#6D6D6D73',
          color: '#6D6D6D',
          text: 'Extra',
        },
      ],
      showEditDetectionTypeDialog: false,
      showEditDetectionTypeUnavailableDialog: false,
      showRepetitiveSelectModal: false,
      showEditStatusDialog: false,
      showFilterDialog: false,
      isFiltered: false,
      snackBarOpen: false,
      // zoom control restriction(s)
      isPressingControlKey: false,
      isInvalidScroll: false,
      invalidScrollTimeout: null,
      loadingForRepetitiveModal: false,
      firstDetectionsWithIn24Hours: [],
    }
  },
  methods: {
    ...mapActions([
      'getRecentLogListInMapview',
      'getLogChildrenAction',
      'setMapViewSelectedLogChildAction',
      'setMapViewSelectedLogAction',
      'getLogInfo',
    ]),
    onEmitClickDetectionTypeEditBtn() {
      this.showEditDetectionTypeDialog = true
    },
    onEmitClickEditStatusBtn() {
      this.showEditStatusDialog = true
    },
    onCloseFilterDialog(evt) {
      this.showFilterDialog = false
      if (evt) {
        this.$store.dispatch('setShowMapViewResponseStatusPopoverAction', false)
        this.$store.dispatch('setMapViewSelectedLogAction', null)
      }
    },
    onClickFilterBtn() {
      this.showFilterDialog = true
    },
    async onCloseDetectionTypeEditDialog(isSave) {
      this.loadingForRepetitiveModal = true

      const {
        data: { logs },
      } = await alert.getFirstDetectionsWithin24Hours(this.mapViewSelectedLog.log_id)
      this.firstDetectionsWithIn24Hours = logs.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())
      // check if there are any first detection logs within 24 hours from current time of same camera
      const CHANGE_TO_FIRST_DETECTION = isSave && Util.isDuplicateLog(this.mapViewSelectedLog)
      // const recentLogsFromSameCamera = this.recentLogList.filter((log) => log.camera_id === this.mapViewSelectedLog.camera_id)
      // const ONE_DAY_AGO = moment().subtract(24, 'hours').format()

      const VALID_FIRST_DETECTIONS_EXIST = this.firstDetectionsWithIn24Hours.length

      this.loadingForRepetitiveModal = false

      this.showEditDetectionTypeDialog = false

      if (isSave && !CHANGE_TO_FIRST_DETECTION && VALID_FIRST_DETECTIONS_EXIST) {
        // this.$log.debug('Valid change to Repetitive Detection # MapMainInterface.vue')
        this.showRepetitiveSelectModal = true
      } else if (isSave && !CHANGE_TO_FIRST_DETECTION && !VALID_FIRST_DETECTIONS_EXIST) {
        // this.$log.debug('Invalid change to Repetitive Detection # MapMainInterface.vue')
        this.showEditDetectionTypeUnavailableDialog = true
      } else if (isSave && CHANGE_TO_FIRST_DETECTION) {
        // this.$log.debug('Change to First Detection # MapMainInterface.vue')
        await this.$store.dispatch('setLogDetectionTypeAction', {
          log_id: this.mapViewSelectedLog.log_id,
          duplicated_log_id: null,
          is_duplicate: false,
        })
        this.onChangeDetectionType()
        this.updateDrawer('first')
      }
    },
    async updateDrawer(toChangeType) {
      this.$store.dispatch('setShowMapViewResponseStatusPopoverAction', false)

      // update selected log info
      const updatedLogInfo = await this.getLogInfo(this.mapViewSelectedLog.log_id)
      if (!!updatedLogInfo) {
        this.setMapViewSelectedLogAction(updatedLogInfo)
      }
      if (toChangeType === 'repetitive') {
        // if accordion is expanded, update duplicate logs list
        if (this.mapviewExpandedAccordionLogId !== -1) {
          await this.getLogChildrenAction(this.mapviewExpandedAccordionLogId)
        }
      }
    },

    onCloseDetectionTypeEditUnavailableDialog() {
      this.showEditDetectionTypeUnavailableDialog = false
    },
    onCloseEditStatusDialog(isSave) {
      this.showEditStatusDialog = false
      if (isSave) {
        this.$store.dispatch('setShowMapViewResponseStatusPopoverAction', false)
        this.getRecentLogListInMapview({
          includeChildLogs: true,
          includeNotificationInformation: true,
          includeDuplicatesByOrganization: true,
          includeResponseStatusInformation: true,
          tabIndex: 1,
          themeName: this.themeName,
        }).then((logs) => {
          if (logs && logs.length && logs.filter((log) => log.log_id === this.mapViewSelectedLog.log_id).length) {
            const updatedLogObject = logs.filter((log) => log.log_id === this.mapViewSelectedLog.log_id)[0]
            this.setMapViewSelectedLogAction(updatedLogObject)
          }
        })
      }
    },
    onCloseRepetitiveSelectModal(saveSuccess) {
      this.firstDetectionsWithIn24Hours = []
      this.showRepetitiveSelectModal = false
      if (saveSuccess) {
        this.onChangeDetectionType()
        this.updateDrawer('repetitive')
      }
    },
    onFilterCheck(filter) {
      this.isFiltered = filter
    },
    // Methods to be utilized when the Detection Type is modified successfully
    onChangeDetectionType() {
      this.snackBarOpen = true
    },
    onMousewheel(evt) {
      if (!this.isPressingControlKey) {
        this.isInvalidScroll = true
        clearTimeout(this.invalidScrollTimeout)
        this.invalidScrollTimeout = setTimeout(() => {
          this.isInvalidScroll = false
        }, 1000)
      }
    },
    onKeydown(evt) {
      if (evt.key === 'Control') {
        this.isPressingControlKey = true
      }
    },
    onKeyup(evt) {
      if (evt.key === 'Control') {
        this.isPressingControlKey = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.map-main-interface {
  flex-grow: 1;
  height: 100%;
  position: relative;

  .map-view-scroll-guide {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 22px;
    font-weight: 400;
    transition-property: opacity;
    transition-duration: 0.8s;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;
    &.visible {
      opacity: 1;
    }
    &.hidden {
      opacity: 0;
    }
  }
  .btn-filter {
    position: absolute;
    top: 17px;
    right: 15px;
    background-color: var(--f-primary-90);
    &.filtered {
      border: 1px solid var(--f-primary-90);
      background-color: var(--f-supplementary-highlight);
    }

    .btn-filter-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      color: var(--f-text-white);
      margin-left: 8px;

      &.filtered {
        color: var(--f-primary-90);
      }
    }
  }

  .legend-map-view {
    position: absolute;
    bottom: 25px;
    left: 15px;
    z-index: 1;
  }
  .notice-map-view {
    position: absolute;
    bottom: 66px;
    left: 15px;
  }
}
</style>
