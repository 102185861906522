<template>
  <div :class="`popover-container ${themeName}`">
    <div class="popover-top">
      <img
        v-if="themeName === 'dark'"
        :class="`popover-btn-close ${themeName}`"
        src="@/assets/Close_Line_12px_1_Icon_dark.svg"
        width="12"
        @click="onClickResponseStatusClose"
      />
      <img
        v-else
        :class="`popover-btn-close ${themeName}`"
        src="@/assets/Close_Line_12px_1_Icon.svg"
        width="12"
        @click="onClickResponseStatusClose"
      />
    </div>
    <div class="popover-bottom">
      <!-- Detection Type -->
      <div :class="`popover-accordion ${isAccordionExpandedFlag === 'detection-type' ? 'accordion-selected' : ''} ${themeName}`">
        <div :class="`popover-accordion-label ${isAccordionExpandedFlag === 'detection-type' ? 'accordion-selected' : ''} ${themeName}`">
          Detection Type
        </div>
        <div class="popover-accordion-expand-icon-container" @click="onClickAccordionExpand('detection-type')">
          <img v-if="isAccordionExpandedFlag === 'detection-type'" :src="require('@/assets/Chevron-Up_16px_Icon-Light.svg')" />
          <img v-else-if="themeName === 'dark'" :src="require('@/assets/Chevron-Down_16px_Icon-Light.svg')" />
          <img v-else :src="require('@/assets/Chevron-Down_16px_Icon-Dark.svg')" />
        </div>
      </div>
      <div class="popover-accordion-content-detection-type" v-if="isAccordionExpandedFlag === 'detection-type'">
        <div class="detection-type-content">
          <div :class="`detection-type-value ${themeName}`">{{ detectionTypeValue }}</div>
          <v-btn
            data-testid="Edit_Text_Button"
            class="detection-type-edit-btn"
            color="#FF5B54"
            depressed
            :disabled="isRoleViewer"
            height="30"
            width="101"
            @click="onClickDetectionTypeEdit()"
          >
            <div class="detection-type-edit-btn-text">Edit</div>
          </v-btn>
        </div>
      </div>
      <!-- Response Status -->
      <div :class="`popover-accordion ${isAccordionExpandedFlag === 'popover' ? 'accordion-selected' : ''} ${themeName}`">
        <div
          :class="`popover-accordion-label ${isAccordionExpandedFlag === 'popover' ? 'accordion-selected' : ''} ${
            isRepetitiveLog || isRoleViewer ? 'disabled' : ''
          } ${themeName}`"
        >
          Response Status
        </div>
        <div
          :class="`popover-accordion-expand-icon-container ${isRepetitiveLog || isRoleViewer ? 'disabled' : ''}`"
          @click="onClickAccordionExpand('popover')"
        >
          <img v-if="(isRepetitiveLog || isRoleViewer) && themeName === 'dark'" :src="require('@/assets/Chevron-Down_16px_Icon_Disabled-Dark.svg')" />
          <img v-else-if="isRepetitiveLog || isRoleViewer" :src="require('@/assets/Chevron-Down_16px_Icon_Disabled-Light.svg')" />
          <img v-else-if="isAccordionExpandedFlag === 'popover'" :src="require('@/assets/Chevron-Up_16px_Icon-Light.svg')" />
          <img v-else-if="themeName === 'dark'" :src="require('@/assets/Chevron-Down_16px_Icon-Light.svg')" />
          <img v-else :src="require('@/assets/Chevron-Down_16px_Icon-Dark.svg')" />
        </div>
      </div>
      <div class="popover-accordion-content-response-status" v-if="isAccordionExpandedFlag === 'popover'">
        <div class="response-status-content">
          <div class="response-status-info">
            <div class="response-status-diagram-container">
              <img width="20" src="@/assets/Check_Circle_20px_Icon_red.svg" />
              <div :class="`popover-diagram-line ${themeName} ${responseStatus === 'Alerted' ? '' : 'red-color'}`"></div>
              <div v-if="responseStatus === 'Alerted'" :class="`popover-icon-placeholder ${themeName}`"></div>
              <img v-else width="20" src="@/assets/Check_Circle_20px_Icon_red.svg" />
              <div :class="`popover-diagram-line ${themeName} ${responseStatus !== 'Responded' ? '' : 'red-color'}`"></div>
              <div v-if="responseStatus !== 'Responded'" :class="`popover-icon-placeholder ${themeName}`"></div>
              <img v-else width="20" src="@/assets/Check_Circle_20px_Icon_red.svg" />
            </div>
            <div class="popover-texts-container">
              <div :class="`popover-label-text ${themeName}`">Alerted</div>
              <div :class="`popover-value-text ${themeName}`">{{ alertedTimestamp }}</div>
              <div :class="`popover-label-text ${themeName}`">Validated</div>
              <div :class="`popover-value-text ${themeName}`">{{ validatedTimestamp }}</div>
              <div :class="`popover-label-text ${themeName}`">Responded</div>
              <div :class="`popover-value-text ${themeName}`">{{ respondedTimestamp }}</div>
            </div>
          </div>
          <v-btn
            class="btn-edit-status"
            color="#FF5B54"
            depressed
            :disabled="isRoleViewer"
            height="30"
            ripple
            width="100"
            @click="onClickEditStatus()"
          >
            <div class="btn-edit-status-text">Edit Status</div>
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import Theme from '@/mixin/theme.mixin'
import Constants from '@/constants'
import Util from '@/util'

export default {
  mixins: [Theme],
  data() {
    return {
      isAccordionExpandedFlag: '',
    }
  },
  computed: {
    ...mapState(['showMapViewResponseStatusPopover', 'mapViewSelectedLog', 'user']),
    isRoleViewer() {
      return this.user.user_role === Constants.ROLE_VIEWER
    },
    detectionTypeValue() {
      return this.isRepetitiveLog ? 'Repetitive Detection' : 'First Detection'
    },
    isRepetitiveLog() {
      return Util.isDuplicateLog(this.mapViewSelectedLog)
    },
    responseStatus() {
      if (!this.mapViewSelectedLog) return ''
      let result = 'Alerted'
      if (this.mapViewSelectedLog.response_status.is_validated) {
        result = 'Validated'
      }
      if (this.mapViewSelectedLog.response_status.is_responded) {
        result = 'Responded'
      }
      return result
    },
    alertedTimestamp() {
      if (!this.mapViewSelectedLog) return '-'
      return !!this.mapViewSelectedLog.response_status.alerted_time
        ? Util.getOffsetDate(this.mapViewSelectedLog.response_status.alerted_time, this.mapViewSelectedLog.offset)
        : '-'
    },
    validatedTimestamp() {
      if (!this.mapViewSelectedLog) return '-'
      return !!this.mapViewSelectedLog.response_status.validated_time
        ? Util.getOffsetDate(this.mapViewSelectedLog.response_status.validated_time, this.mapViewSelectedLog.offset)
        : '-'
    },
    respondedTimestamp() {
      if (!this.mapViewSelectedLog) return '-'
      return !!this.mapViewSelectedLog.response_status.responded_time
        ? Util.getOffsetDate(this.mapViewSelectedLog.response_status.responded_time, this.mapViewSelectedLog.offset)
        : '-'
    },
  },
  methods: {
    onClickResponseStatusClose() {
      this.$store.dispatch('setShowMapViewResponseStatusPopoverAction', false)
    },
    onClickDetectionTypeEdit() {
      this.$emit('click-detection-type-edit-btn')
    },
    onClickEditStatus() {
      this.$emit('click-edit-status-btn')
    },
    onClickAccordionExpand(flag) {
      if ((!this.isRepetitiveLog && !this.isRoleViewer) || flag === 'detection-type') {
        this.isAccordionExpandedFlag = this.isAccordionExpandedFlag !== flag ? flag : ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.popover-container {
  position: absolute;
  left: 0;
  top: 0;
  // height: 320px;
  width: 228px;
  background-color: #ffffff;
  border-radius: 0px 0px 16px 0px;
  filter: drop-shadow(5px 5px 20px rgba(0, 0, 0, 0.2));
  &.dark {
    background-color: #25262c;
  }

  .popover-top {
    height: 42px;
    width: 100%;
    display: flex;
    position: relative;
    align-items: center;

    .popover-btn-close {
      position: absolute;
      top: 16px;
      right: 16px;
      cursor: pointer;
    }
  }

  .popover-accordion-content-detection-type {
    width: 228px;
    padding: 30px 24px;
    .detection-type-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .detection-type-value {
        height: 33px;
        width: 181px;
        border-radius: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 20px;
        background-color: #0000001f;
        color: #363636;

        &.dark {
          background-color: #323340;
          color: #ffffffde;
        }
      }

      .detection-type-edit-btn {
        border-radius: 8px;

        .detection-type-edit-btn-text {
          letter-spacing: 0;
          font-weight: 700;
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
  }

  .popover-accordion-content-response-status {
    display: flex;
    height: 269px;
    padding: 11px 32px 30px 32px;

    .response-status-content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .response-status-info {
        display: flex;
        .response-status-diagram-container {
          width: 20px;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 10px;

          .popover-icon-placeholder {
            height: 20px;
            width: 20px;
            border-radius: 50%;
            border: 2px solid #d8d9da;
          }

          .popover-diagram-line {
            height: 44px;
            width: 2px;
            background-color: #d8d9da;

            &.red-color {
              background-color: #ff5b54;
            }
          }
        }

        .popover-texts-container {
          flex-grow: 1;
          display: flex;
          flex-direction: column;

          .popover-label-text {
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 5px;
            color: #363636;
            &.dark {
              color: #ffffffde;
            }
          }

          .popover-value-text {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            margin-bottom: 21px;
            color: #363636;
            white-space: nowrap;
            &.dark {
              color: #ffffffde;
            }
          }
        }
      }

      .btn-edit-status {
        border-radius: 8px;

        .btn-edit-status-text {
          letter-spacing: 0;
          font-weight: 700;
          font-size: 14px;
          color: #ffffff;
        }
      }
    }
  }

  .popover-bottom {
    display: flex;
    flex-direction: column;

    .popover-accordion {
      display: flex;
      height: 56px;
      border-top: 1px solid #0000001f;

      &.accordion-selected {
        background-color: #ff827c;
      }
      &.dark {
        border-top: 1px solid #323340;
      }

      .popover-accordion-label {
        font-weight: 700;
        font-size: 16px;
        color: #363636;
        display: flex;
        align-items: center;
        padding-left: 16px;
        width: 178px;
        &.accordion-selected {
          color: #ffffff;
        }

        &.dark {
          color: #ffffffde;
        }

        &.disabled {
          color: #0000001f;
        }

        &.disabled.dark {
          color: #ffffff61;
        }
      }

      .popover-accordion-expand-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50px;
        cursor: pointer;

        &.disabled {
          cursor: auto;
        }
      }
    }
  }
}
</style>
