<template>
  <v-snackbar v-model="value" timeout="5000" :color="color" rounded="pill" max-width="300px" :class="{ mobile }">
    {{ message }}
    <template v-slot:action="{ attrs }">
      <img v-bind="attrs" @click="value = false" src="@/assets/cancel_icon.svg" class="icon-close" />
    </template>
  </v-snackbar>
</template>

<script>
export default {
  props: {
    value: Boolean,
    color: String,
    message: String,
    mobile: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.$emit('input', val)
      }
    },
  },
}
</script>

<style scoped>
.icon-close {
  width: 14px;
  margin-right: 16px;
  cursor: pointer;
}
.mobile >>> .v-snack__content {
  font-size: 15px;
}
.mobile .icon-close {
  width: 14px;
  margin: 0px 23px;
}
</style>
