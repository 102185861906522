<template>
  <v-dialog v-model="value" @keydown.esc="hide" width="1005" height="606" persistent no-click-animation>
    <v-card :class="`live-view-dialog-card ${themeName}`" data-testid="LiveView_Modal">
      <div :class="`live-view-dialog-title ${themeName}`">
        <div v-if="providerLogoUrl" class="live-view-camera-provider">
          Cameras by
          <img :src="providerLogoUrl" width="113px" style="margin-left: 7px" />
        </div>
        Live View
      </div>
      <div class="live-view-dialog-close" @click="hide" data-testid="Close_Button">
        <img v-if="$vuetify.theme.dark" src="@/assets/Exit_Icon.svg" width="16px" />
        <img v-else src="@/assets/Exit_Icon_Black.svg" width="16px" />
      </div>
      <div v-if="value" style="height: 541px; width: 100%; padding: 0 40px 20px 40px">
        <ImagePlayer
          data-testid="Main_Stream_Box"
          :currentCamImageSrc="currentCamImageSrc"
          :currentLog="null"
          :hasAfterImages="false"
          :monitor="true"
          :play="play"
          :active="canvasActiveOption"
          :frame="frameIndex"
          :download="play !== 'play'"
          :isloading="!selectedLogInfo"
          @frame="onImagePlayerUpdateFrame"
          @loaded="
            loadedImages = $event
            loading = false
          "
          @stop="onStopPlayer"
          style="height: 100%; background-color: black"
          :liveViewDialog="true"
        ></ImagePlayer>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import Theme from '@/mixin/theme.mixin'
import ImagePlayer from '@common/ImagePlayer/ImagePlayer'
import Constants from '@/constants'
import Util from '@/util'

export default {
  mixins: [Theme],
  components: { ImagePlayer },
  props: {
    value: Boolean,
    selectedLogInfo: Object,
  },
  data() {
    return {
      loading: false,
      loadedImages: [],
      currentFrameIndex: 0,
      frameIndex: 0,
      play: 'stop',
      playing: false,
      didPlay: false,
      frameMode: '',
      currentCamImageSrc: '',
      timeoutSetCamImageSrc: null,
      canvasActiveOption: {
        selectable: false,
        wheel: false,
        dragLeftButton: true,
      },
    }
  },
  watch: {
    value(newVal, oldVal) {
      if (newVal) {
        // this.$log.debug('LiveViewDialog$watch$value', newVal, 1)
        this.getFirstImage()
        this.intervalSetCamImageSrc()
      } else {
        // this.$log.debug('LiveViewDialog$watch$value', newVal)
        this.stopIntervalSetCamImageSrc()
      }
    },
  },
  beforeDestroy() {
    this.stopIntervalSetCamImageSrc()
  },
  computed: {
    ...mapState(['camMap']),
    matchedCameraPath() {
      return !!this.camMap && !!this.selectedLogInfo ? this.camMap[this.selectedLogInfo.camera_id].path : ''
    },
    providerLogoUrl() {
      if (this.selectedLogInfo.provider_dark_logo_path || this.selectedLogInfo.provider_light_logo_path) {
        const currentLogoUrl =
          this.themeName === 'dark' ? this.selectedLogInfo.provider_dark_logo_path : this.selectedLogInfo.provider_light_logo_path

        return Util.getImageUrl(currentLogoUrl)
      }

      return null
    },
  },
  methods: {
    hide() {
      this.$emit('close')
    },
    onImagePlayerUpdateFrame(index) {
      this.currentFrameIndex = index
    },
    stopIntervalSetCamImageSrc() {
      clearTimeout(this.timeoutSetCamImageSrc)
    },
    getFirstImage() {
      this.currentCamImageSrc = Util.getImageUrl(this.matchedCameraPath, true)
    },
    intervalSetCamImageSrc() {
      clearTimeout(this.timeoutSetCamImageSrc)
      this.timeoutSetCamImageSrc = setTimeout(() => {
        this.currentCamImageSrc = Util.getImageUrl(this.matchedCameraPath, true)
        this.intervalSetCamImageSrc()
      }, Constants.INTERVAL_SITE_LOG)
    },
  },
}
</script>

<style lang="scss" scoped>
.live-view-dialog-card {
  .live-view-dialog-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #363636;
    &.dark {
      color: #ffffffde;
    }

    .live-view-camera-provider {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      position: absolute;
      top: 25px;
      left: 40px;
      cursor: pointer;
    }
  }
  .live-view-dialog-close {
    position: absolute;
    top: 25px;
    right: 30px;
    cursor: pointer;
  }
}
</style>
