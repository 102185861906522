<template>
  <v-dialog v-model="value" max-width="400px" @keydown.esc="closeDialog" persistent no-click-animation>
    <v-card :color="themeName === 'dark' ? '#25262C' : '#ffffff'" data-testid="EditEventType_Modal">
      <!-- title -->
      <div :class="`edit-detection-type-title ${themeName}`">Edit Detection</div>
      <!-- status slider -->
      <div class="edit-detection-type-toggle-area">
        <div class="options-all">
          <div class="option-container" style="width: 174px; margin-bottom: 10px" @click="onClickOption(0)">
            <input type="radio" :class="`radio-btn ${themeName}`" name="option" :checked="!selectedOptionIndex" />
            <label :class="`option-text ${themeName}`">Mark as First Detection</label>
          </div>
          <div class="option-container" @click="onClickOption(1)">
            <input type="radio" :class="`radio-btn ${themeName}`" name="option" :checked="selectedOptionIndex" />
            <label :class="`option-text ${themeName}`">Mark as Repetitive Detection</label>
          </div>
        </div>
      </div>
      <!-- cancel/save buttons -->
      <div class="edit-detection-type-buttons-container">
        <v-btn
          class="edit-detection-type-button"
          :color="themeName === 'dark' ? '#4B4F64' : '#D3D5DD'"
          height="60"
          ripple
          width="160"
          @click="onClickBtn(false)"
        >
          <div :class="`edit-detection-type-button-text-dark ${themeName}`">Cancel</div>
        </v-btn>
        <v-btn class="edit-detection-type-button" color="#3C7EFF" height="60" ripple width="240" @click="onClickBtn(true)">
          <div v-if="!loadingForRepetitiveModal" :class="`edit-detection-type-button-text ${themeName}`">Save</div>
          <v-progress-circular v-else indeterminate color="#FFFFFF"></v-progress-circular>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Theme from '@/mixin/theme.mixin'
import Util from '@/util'

export default {
  mixins: [Theme],
  props: {
    value: Boolean,
    selectedLogInfo: Object,
    loadingForRepetitiveModal: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(val) {
      if (val && this.selectedLogInfo) {
        this.selectedOptionIndex = Util.isDuplicateLog(this.selectedLogInfo) ? 1 : 0
      }
    },
  },
  data() {
    return {
      selectedOptionIndex: 0,
    }
  },
  methods: {
    onClickOption(index) {
      this.selectedOptionIndex = index
    },
    onClickBtn(isSave) {
      const defaultSelection = Util.isDuplicateLog(this.selectedLogInfo) ? 1 : 0
      if (!isSave || (isSave && this.selectedOptionIndex !== defaultSelection)) {
        this.closeDialog(isSave)
      }
    },
    closeDialog(isSave) {
      this.$emit('close-detection-type-edit', isSave)
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-detection-type-title {
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #363636;
  &.dark {
    color: #ffffffde;
  }
}

.edit-detection-type-toggle-area {
  height: 111px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 50px 0;

  .options-all {
    display: flex;
    flex-direction: column;
    .option-container {
      display: flex;
      align-items: center;
      .radio-btn {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 16px;
        height: 16px;
        cursor: pointer;
        margin-right: 10px;
        &.dark {
          background-image: url('~@/assets/Radio_Button_Empty_White.svg');
        }
        &.light {
          background-image: url('~@/assets/Radio_Button_Empty_Black.svg');
        }
      }
      .radio-btn:checked {
        &.dark {
          background-image: url('~@/assets/Radio_Button_Filled_White.svg');
        }
        &.light {
          background-image: url('~@/assets/Radio_Button_Filled_Black.svg');
        }
      }

      .option-text {
        font-weight: 400;
        font-size: 14px;
        cursor: pointer;
        color: #363636;
        &.dark {
          color: #ffffffde;
        }
      }
    }
  }
}

.edit-detection-type-buttons-container {
  display: flex;
  padding: 0;

  .edit-detection-type-button {
    border-radius: 0px;
    .edit-detection-type-button-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      color: #ffffff;

      &.dark {
        color: #ffffffde;
      }
    }
    .edit-detection-type-button-text-dark {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      color: #363636;
      &.dark {
        color: #ffffff;
      }
    }
  }
}
</style>
