<template>
  <v-dialog v-model="value" width="1002px" :persistent="true" no-click-animation>
    <section :class="`repetitive-select-modal ${themeName}`">
      <div :class="`close-btn ${themeName}`">
        <img v-if="themeName === 'dark'" src="@/assets/Close_Line_12px_1_Icon_dark.svg" alt="close" width="12px" @click="closeModal(false)" />
        <img v-else src="@/assets/Close_Line_12px_1_Icon.svg" alt="close" width="10px" @click="closeModal(false)" />
      </div>
      <h2 :class="`modal-title ${themeName}`">With which detection is it Repetitive?</h2>
      <div class="content-continer">
        <div class="content-wrapper">
          <span :class="`content-title ${themeName} current`">Current Event</span>
          <div class="content">
            <DetectImage :logInfo="mapViewSelectedLog" />
            <div :class="`content-text ${themeName}`">{{ getCamNameAndCreateTime(mapViewSelectedLog) }}</div>
          </div>
        </div>
        <div :class="`content-wrapper`">
          <span :class="`content-title ${themeName} comparitive`">Comparative Events</span>
          <div :class="`content repetitive ${themeName}`" v-if="!imageViewMode">
            <div :class="`content-description ${themeName}`">First Detections within 24 hours</div>
            <div style="height: 128px; overflow-y: auto">
              <div v-for="(info, idx) in firstDetectionsWithIn24Hours" :key="idx" class="repetitive-info" @click="onClickRepetitiveinfo(info)">
                <input type="radio" :class="`radio-btn ${themeName}`" name="repetitive" :id="info.log_id" />
                <label :for="info.log_id" :class="`repetitive-text ${themeName}`">{{ getCamNameAndCreateTime(info) }}</label>
              </div>
            </div>
            <SmallButton @onClick="changeToViewMode" :bgColor="'#FF5B54'" :disable="!selectedInfo" :width="101" :height="30" class="view-btn"
              >View</SmallButton
            >
          </div>
          <div v-else :class="`content ${imageViewMode ? 'view' : ''} ${themeName}`">
            <div :class="`return-btn ${themeName}`" @click="changeToListMode">
              <img src="@/assets/Return_Icon_Button_Red.svg" alt="return" /> Return
            </div>
            <DetectImage :logInfo="selectedInfo" />
            <div :class="`content-text ${themeName}`">{{ getCamNameAndCreateTime(selectedInfo) }}</div>
          </div>
        </div>
      </div>
      <SmallButton @onClick="onClickSaveButton" :bgColor="'#FF5B54'" :disable="!selectedInfo" class="save-btn">Save</SmallButton>
    </section>
  </v-dialog>
</template>

<script>
import moment from 'moment'
import DetectImage from '@common/ImagePlayer/DetectImage'
import SmallButton from '@item/button/SmallButton'
import Util from '@/util'

export default {
  components: { DetectImage, SmallButton },
  data() {
    return {
      imageViewMode: false,
      selectedInfo: null,
      mapViewSelectedLog: null,
    }
  },
  props: {
    value: Boolean,
    selectedFirstDetectionLog: {
      type: Object,
      default: null,
    },
    firstDetectionsWithIn24Hours: {
      type: Object,
      default: [],
    },
  },
  created() {
    this.mapViewSelectedLog = this.selectedFirstDetectionLog
  },
  computed: {
    themeName() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
  },
  methods: {
    getCamNameAndCreateTime(info) {
      if (info) {
        const date = Util.getOffsetDate(info.created_at, info.offset)
        return `[${moment(date).format('YYYY-MM-DD HH:mm:ss')}] ${info.camera_name}`
      } else return '-'
    },
    changeToViewMode() {
      if (this.selectedInfo !== null) this.imageViewMode = true
    },
    changeToListMode() {
      this.imageViewMode = false
      this.$nextTick(() => {
        const repetitiveInfo = document.getElementById(`${this.selectedInfo.log_id}`)
        repetitiveInfo.click()
      })
    },
    onClickRepetitiveinfo(info) {
      this.selectedInfo = info
    },
    async onClickSaveButton() {
      if (this.selectedInfo) {
        await this.$store.dispatch('setLogDetectionTypeAction', {
          log_id: this.mapViewSelectedLog.log_id,
          duplicated_log_id: this.selectedInfo.log_id,
          is_duplicate: true,
        })
        this.closeModal(true)
      }
    },
    closeModal(saveSuccess) {
      this.$emit('close', saveSuccess)
    },
  },
}
</script>

<style lang="scss" scoped>
.repetitive-select-modal {
  padding: 20px 40px 50px 40px;
  &.light {
    background-color: #ffffff;
  }
  &.dark {
    background-color: var(--f-bg-middle);
  }
}

.content-continer {
  display: flex;
  margin-top: 40px;
  justify-content: space-between;
}

.content-wrapper {
  width: calc(50% - 15px);
  display: flex;
  flex-direction: column;
}

.modal-title {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
  color: var(--f-text-black);
  line-height: 22px;
  &.dark {
    color: #ffffffde;
  }
}

.content-title {
  padding: 8px 24px;
  font-weight: 700;
  font-size: 14px;
  border-radius: 16px;
  text-align: center;
  display: inline-block;
  margin: 0 auto;
  line-height: 17px;
  &.current {
    width: 137px;
  }
  &.comparitive {
    width: 178px;
  }

  &.dark {
    background-color: var(--f-bg-high);
    color: #ffffffde;
  }
  &.light {
    background-color: var(--f-text-black-low);
    color: var(--f-text-black);
  }
}

.content {
  border-radius: 16px;
  margin-top: 45px;
  &.repetitive {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    &.dark {
      background-color: var(--f-bg-high);
    }
    &.light {
      background-color: var(--f-light-gray-1);
    }
  }
  &.view {
    margin-top: 5px;
    color: #000000de;
    &.dark {
      color: #ffffff;
    }
  }
}

.content-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 10px;
  color: var(--f-text-black);
  &.dark {
    color: #ffffffde;
  }
}
.repetitive-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: var(--f-text-black);
  flex-grow: 1;
  padding-left: 10px;
  &.dark {
    color: #ffffffde;
  }
}
.content-description {
  text-align: center;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: 5px;
  color: var(--f-text-black);
  &.dark {
    color: #ffffffde;
  }
}

.repetitive-info {
  display: flex;
  align-items: center;
  margin: 15px 0 0 39px;
}

.radio-btn {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  &.dark {
    background-image: url('~@/assets/Radio_Button_Empty_White.svg');
  }
  &.light {
    background-image: url('~@/assets/Radio_Button_Empty_Black.svg');
  }
}
.radio-btn:checked {
  &.dark {
    background-image: url('~@/assets/Radio_Button_Filled_White.svg');
  }
  &.light {
    background-image: url('~@/assets/Radio_Button_Filled_Black.svg');
  }
}

.return-btn {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;
  cursor: pointer;
  width: 82px;
  img {
    margin-right: 10px;
  }
  color: var(--f-text-black);
  &.dark {
    color: #ffffffde;
  }
}

.view-btn {
  align-self: center;
  margin-top: 31px;
  font-size: 14px !important;
  &.light {
    color: #ffffff;
  }
}

.close-btn {
  text-align: end;
  margin-bottom: 18px;
  margin-right: -20px;
  height: 12px;
  img {
    cursor: pointer;
  }
}

.save-btn {
  margin: 0 auto;
  margin-top: 40px;
}
</style>
