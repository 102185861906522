<template>
  <v-dialog v-model="value" max-width="400px" @keydown.esc="closeDialog" persistent no-click-animation>
    <v-card :color="themeName === 'dark' ? '#25262C' : '#ffffff'">
      <!-- title -->
      <div :class="`edit-detection-type-unavailable-title ${themeName}`">Edit Unavailable</div>
      <!-- status slider -->
      <div class="edit-detection-type-unavailable-area">
        <div :class="`edit-detection-type-unavailable-content ${themeName}`" v-html="contentText"></div>
      </div>
      <!-- okay button -->
      <div class="edit-detection-type-buttons-container">
        <v-btn class="edit-detection-type-button" color="#3C7EFF" height="60" ripple width="400" @click="closeDialog()">
          <div :class="`edit-detection-type-button-text ${themeName}`">Okay</div>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Theme from '@/mixin/theme.mixin'

export default {
  mixins: [Theme],
  props: {
    value: Boolean,
  },
  data() {
    return {
      contentText:
        '<div>This event cannot be edited as a</div><div>Repetitive Detection.</div><div>There is no First Detection from the</div><div>same camera within 24 hours.</div>',
    }
  },
  methods: {
    closeDialog() {
      this.$emit('close-detection-type-edit-unavailable')
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-detection-type-unavailable-title {
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 18px;
  color: #363636;
  &.dark {
    color: #ffffffde;
  }
}

.edit-detection-type-unavailable-area {
  height: 142px;
  width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0 40px 0;

  .edit-detection-type-unavailable-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #363636;

    &.dark {
      color: #ffffffde;
    }
  }
}

.edit-detection-type-buttons-container {
  display: flex;
  padding: 0;

  .edit-detection-type-button {
    border-radius: 0px;
    .edit-detection-type-button-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      color: #ffffff;

      &.dark {
        color: #ffffffde;
      }
    }
  }
}
</style>
