<template>
  <div class="image-wrapper" @click="onClickCavansImg">
    <canvas ref="detection"></canvas>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import DetectImage from '@components/canvas/DetectImage'
import Util from '@/util'

export default {
  props: {
    logInfo: {
      type: Object,
      default: null,
    },
  },
  async mounted() {
    if (this.logInfo !== null) {
      const currentLogImageUrl = Util.getImageUrl(this.logInfo.detected_image_path)
      if (this.logInfo.boxes?.length) {
        new DetectImage(this.$refs.detection, this.logInfo.boxes, currentLogImageUrl)
      } else if (this.logInfo.objects?.length) {
        new DetectImage(this.$refs.detection, this.logInfo.objects, currentLogImageUrl)
      } else {
        const detailInfo = await this.getLog(this.logInfo.log_id)
        new DetectImage(this.$refs.detection, detailInfo.objects, currentLogImageUrl)
      }
    }
  },
  methods: {
    ...mapActions(['getLog']),
    onClickCavansImg() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss" scoped>
.image-wrapper {
  border-radius: 16px;
  overflow: hidden;
  margin: 0 auto;
}
</style>
