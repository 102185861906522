import { fabric } from 'fabric'
import Box from './Box'
import { transFromDataToBox, transFromBoxToData } from './util'

export default class DetectImage {
  imageUrl
  detectObjects
  canvasElement
  canvas
  image

  constructor(canvasElement, detectObjects, imageUrl) {
    this.canvasElement = canvasElement
    this.imageUrl = imageUrl
    this.detectObjects = detectObjects
    this.initialize()
  }
  initialize() {
    this.canvas = new fabric.Canvas(this.canvasElement, {
      width: 446,
      height: 251,
    })
    this.loadImage()
  }

  async loadImage() {
    await fabric.Image.fromURL(this.imageUrl, (img) => {
      img.set('selectable', false)
      img.set('hoverCursor', 'default')
      this.fitCanvas(img)
      this.image = img
      this.canvas.add(img)
      this.canvas.renderAll()
      this.drawDetectionBox()
    })
  }

  fitCanvas(imageObj) {
    const canvasW = this.canvas.get('width')
    const canvasH = this.canvas.get('height')
    const ratioW = canvasW / imageObj.width
    const ratioH = canvasH / imageObj.height
    if (ratioW < ratioH) {
      imageObj.scale(ratioW)
    } else {
      imageObj.scale(ratioH)
    }
    this.canvas.centerObject(imageObj)
  }

  drawDetectionBox() {
    this.detectObjects.forEach((detectObject) => {
      const obj = transFromDataToBox(detectObject, this.canvasElement, this.image)
      const box = new Box({ ...obj, selectable: false })
      box.draw(this.canvas)
    })
  }
}
