<template>
  <v-dialog v-model="value" max-width="400px" @keydown.esc="closeDialog" persistent no-click-animation>
    <v-card :color="themeName === 'dark' ? '#25262C' : '#ffffff'">
      <!-- title -->
      <div :class="`edit-status-title ${themeName}`">Edit Status</div>
      <!-- log info -->
      <div :class="`edit-status-log-info-container ${themeName}`">
        <div>{{ timestampText }}</div>
        <div style="font-weight: 400">{{ cameraName }}</div>
      </div>
      <!-- status slider -->
      <div class="edit-status-toggle-area">
        <div class="edit-status-slider">
          <div :class="`edit-status-slider-toggle ${getAlertedToggleClass}`" @click="onClickToggle('Alerted')">
            <div v-if="status === 'Alerted'" :class="`edit-status-slider-toggle-inner ${themeName}`"></div>
          </div>
          <div :class="`edit-status-slider-line ${getFirstSliderLineDisabledClass}`"></div>
          <div :class="`edit-status-slider-toggle ${themeName}`" @click="onClickToggle('Validated')">
            <div v-if="status === 'Validated'" :class="`edit-status-slider-toggle-inner ${themeName}`"></div>
          </div>
          <div :class="`edit-status-slider-line ${getSecondSliderLineDisabledClass}`"></div>
          <div :class="`edit-status-slider-toggle ${getRespondedToggleClass}`" @click="onClickToggle('Responded')">
            <div v-if="status === 'Responded'" :class="`edit-status-slider-toggle-inner ${themeName}`"></div>
          </div>
        </div>
        <div class="edit-status-labels-container">
          <div :class="`edit-status-label ${isAlertedDisabled ? 'label-disabled' : ''} ${themeName}`">Alerted</div>
          <div :class="`edit-status-label ${themeName}`">Validated</div>
          <div :class="`edit-status-label ${isRespondedDisabled ? 'label-disabled' : ''} ${themeName}`">Responded</div>
        </div>
      </div>
      <!-- cancel/save buttons -->
      <div class="edit-status-buttons-container">
        <v-btn
          class="edit-status-button"
          :color="themeName === 'dark' ? '#4B4F64' : '#D3D5DD'"
          height="60"
          ripple
          width="160"
          @click="onClickBtn(false)"
        >
          <div :class="`edit-status-button-text-dark ${themeName}`">Cancel</div>
        </v-btn>
        <v-btn class="edit-status-button" color="#3C7EFF" height="60" ripple width="240" @click="onClickBtn(true)">
          <div class="edit-status-button-text">Save</div>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import Theme from '@/mixin/theme.mixin'
import moment from 'moment'
import Util from '@/util'

export default {
  mixins: [Theme],
  props: {
    value: Boolean,
    selectedLogInfo: Object,
    responseStatus: String,
  },
  watch: {
    value(newVal, old) {
      if (newVal) {
        this.status = this.responseStatus
      }
    },
  },
  data() {
    return {
      status: 'Alerted',
    }
  },
  computed: {
    timestampText() {
      if (!this.selectedLogInfo) {
        return ''
      }
      return '[' + Util.getOffsetDate(this.selectedLogInfo.created_at, this.selectedLogInfo.offset) + ']'
    },
    cameraName() {
      if (!this.selectedLogInfo) {
        return ''
      }
      return this.selectedLogInfo.camera_name
    },
    getAlertedToggleClass() {
      let result = 'toggle'
      if (this.responseStatus === 'Responded') {
        result += '-disabled'
      }
      if (this.themeName === 'dark') {
        result += '-dark'
      }
      return result
    },
    getRespondedToggleClass() {
      let result = 'toggle'
      if (this.responseStatus === 'Alerted') {
        result += '-disabled'
      }
      if (this.themeName === 'dark') {
        result += '-dark'
      }
      return result
    },
    getFirstSliderLineDisabledClass() {
      let result = 'line'
      if (this.responseStatus === 'Responded') {
        result += '-disabled'
      }
      if (this.themeName === 'dark') {
        result += '-dark'
      }
      return result
    },
    getSecondSliderLineDisabledClass() {
      let result = 'line'
      if (this.responseStatus === 'Alerted') {
        result += '-disabled'
      }
      if (this.themeName === 'dark') {
        result += '-dark'
      }
      return result
    },
    isAlertedDisabled() {
      return this.responseStatus === 'Responded'
    },
    isRespondedDisabled() {
      return this.responseStatus === 'Alerted'
    },
  },
  methods: {
    onClickToggle(newStatus) {
      const DISABLED_CASE_ALERTED = this.responseStatus === 'Responded' && newStatus === 'Alerted'
      const DISABLED_CASE_RESPONDED = this.responseStatus === 'Alerted' && newStatus === 'Responded'
      if (!DISABLED_CASE_ALERTED && !DISABLED_CASE_RESPONDED) {
        this.status = newStatus
      }
    },
    onClickBtn(isSave) {
      if (isSave) {
        // TODO: implement update API for status of log
        let response_status = 1
        if (this.status !== 'Alerted') {
          response_status = this.status === 'Validated' ? 2 : 3
        }
        this.$store.dispatch('setLogResponseStatusAction', {
          log_id: this.selectedLogInfo.log_id,
          response_status,
        })
      }
      this.closeDialog(isSave)
    },
    closeDialog(isSave) {
      this.$emit('close-status-edit', isSave)
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-status-title {
  padding-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #363636;
  &.dark {
    color: #ffffffde;
  }
}

.edit-status-log-info-container {
  height: 66px;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #363636;
  &.dark {
    color: #ffffffde;
  }
}

.edit-status-toggle-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 0 60px 0;
  .edit-status-slider {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 14px;
    .edit-status-slider-toggle {
      height: 16px;
      width: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      border: 2px solid #363636;
      cursor: pointer;

      &.dark {
        border: 2px solid #ffffffde;
      }

      &.toggle-dark {
        border: 2px solid #ffffffde;
        cursor: pointer;
      }
      &.toggle-disabled {
        border: 2px solid #0000001f;
        cursor: default;
      }

      &.toggle-disabled-dark {
        border: 2px solid #ffffff61;
        cursor: default;
      }

      .edit-status-slider-toggle-inner {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: #363636;
        &.dark {
          background-color: #ffffffde;
        }
      }
    }

    .edit-status-slider-line {
      height: 2px;
      width: 82px;
      background-color: #363636;
      opacity: 1;

      &.line-dark {
        background-color: #ffffffde;
      }
      &.line-disabled {
        background-color: #0000001f;
      }
      &.line-disabled-dark {
        background-color: #ffffff61;
      }
    }
  }

  .edit-status-labels-container {
    width: 280px;
    display: flex;
    justify-content: space-between;
    .edit-status-label {
      text-align: center;
      width: 80px;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;
      color: #363636;
      opacity: 1;

      &.label-disabled {
        opacity: 0.38;
      }

      &.dark {
        color: #ffffffde;
      }
    }
  }
}

.edit-status-buttons-container {
  display: flex;
  padding: 0;

  .edit-status-button {
    border-radius: 0px;
    .edit-status-button-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      color: #ffffffde;
    }
    .edit-status-button-text-dark {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0;
      color: #363636;
      &.dark {
        color: #ffffffde;
      }
    }
  }
}
</style>
