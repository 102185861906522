<template>
  <v-dialog v-model="value" persistent no-click-animation width="600px">
    <v-card :class="`filter-dialog-card ${themeName}`">
      <div :class="`filter-dialog-title ${themeName}`">Filter</div>
      <div class="filter-dialog-close" @click="hide(false)">
        <img v-if="$vuetify.theme.dark" src="@/assets/Exit_Icon.svg" width="15px" />
        <img v-else src="@/assets/Exit_Icon_Black.svg" width="15px" />
      </div>
      <div :class="`filter-dialog-content-container ${themeName}`">
        <div :class="`filter-dialog-content-top-text ${themeName}`">Show:</div>
        <!-- Detection filter -->
        <div :class="`filter-dialog-content-section-title ${themeName}`">Detection</div>
        <div :class="`filter-dialog-content-section-options ${themeName}`" style="margin-bottom: 30px">
          <div
            v-for="(detectionTypeOption, cIndex) in detectionTypeOptionList"
            :key="detectionTypeOption"
            class="filter-dialog-option-container"
            @click="onClickDetectionTypeOption(cIndex)"
          >
            <div class="filter-dialog-option-container-img-container">
              <img
                v-if="themeName === 'dark' && detectionTypeOption.isSelected"
                src="@/assets/Radio_Button_Filled_White.svg"
                height="16"
                width="16"
                style="cursor: pointer"
              />
              <img
                v-else-if="detectionTypeOption.isSelected"
                src="@/assets/Radio_Button_Filled_Black.svg"
                height="16"
                width="16"
                style="cursor: pointer"
              />
              <img
                v-else-if="themeName === 'dark' && !detectionTypeOption.isSelected"
                src="@/assets/Radio_Button_Empty_White.svg"
                height="16"
                width="16"
                style="cursor: pointer"
              />
              <img v-else src="@/assets/Radio_Button_Empty_Black.svg" height="16" width="16" style="cursor: pointer" />
            </div>
            <div :class="`filter-dialog-content-option-text ${themeName}`" style="line-height: 21px">{{ detectionTypeOption.text }}</div>
          </div>
        </div>
        <!-- Camera filter -->
        <div :class="`filter-dialog-content-section-title ${themeName}`">Camera</div>
        <div :class="`filter-dialog-content-section-options ${themeName}`" style="margin-bottom: 30px">
          <div
            v-for="(cameraFilterOption, cIndex) in cameraFilterOptionList"
            :key="cameraFilterOption"
            class="filter-dialog-option-container"
            @click="onClickCameraOption(cIndex)"
          >
            <div class="filter-dialog-option-container-img-container">
              <img
                v-if="themeName === 'dark' && cameraFilterOption.isSelected"
                src="@/assets/Radio_Button_Filled_White.svg"
                height="16"
                width="16"
                style="cursor: pointer"
              />
              <img
                v-else-if="cameraFilterOption.isSelected"
                src="@/assets/Radio_Button_Filled_Black.svg"
                height="16"
                width="16"
                style="cursor: pointer"
              />
              <img
                v-else-if="themeName === 'dark' && !cameraFilterOption.isSelected"
                src="@/assets/Radio_Button_Empty_White.svg"
                height="16"
                width="16"
                style="cursor: pointer"
              />
              <img v-else src="@/assets/Radio_Button_Empty_Black.svg" height="16" width="16" style="cursor: pointer" />
            </div>
            <div :class="`filter-dialog-content-option-text ${themeName}`" style="line-height: 21px">{{ cameraFilterOption.text }}</div>
          </div>
        </div>
        <!-- Detection within -->
        <div :class="`filter-dialog-content-section-title ${themeName}`">Detection within</div>
        <div :class="`filter-dialog-content-section-options ${themeName}`" style="flex-wrap: wrap; height: 70px; margin-bottom: 30px">
          <div
            v-for="(detectionWithinFilterOption, dIndex) in detectionWithinFilterOptionList"
            :key="detectionWithinFilterOption"
            class="filter-dialog-option-container"
            style="margin-right: 0; width: 30%; position: relative"
          >
            <img
              v-if="detectionWithinFilterOption.isSelected && dIndex === 4 && !isCustomDateSelected"
              src="@/assets/Radio_Button_Filled_Red.svg"
              height="16"
              width="16"
              class="filter-dialog-content-toggle-image"
              @click="onClickDetectionWithinOption(dIndex)"
            />
            <img
              v-else-if="themeName === 'dark' && detectionWithinFilterOption.isSelected"
              src="@/assets/Radio_Button_Filled_White.svg"
              height="16"
              width="16"
              class="filter-dialog-content-toggle-image"
              @click="onClickDetectionWithinOption(dIndex)"
            />
            <img
              v-else-if="detectionWithinFilterOption.isSelected"
              src="@/assets/Radio_Button_Filled_Black.svg"
              height="16"
              width="16"
              class="filter-dialog-content-toggle-image"
              @click="onClickDetectionWithinOption(dIndex)"
            />
            <img
              v-else-if="themeName === 'dark' && !detectionWithinFilterOption.isSelected"
              src="@/assets/Radio_Button_Empty_White.svg"
              height="16"
              width="16"
              class="filter-dialog-content-toggle-image"
              @click="onClickDetectionWithinOption(dIndex)"
            />
            <img
              v-else
              src="@/assets/Radio_Button_Empty_Black.svg"
              height="16"
              width="16"
              class="filter-dialog-content-toggle-image"
              @click="onClickDetectionWithinOption(dIndex)"
            />
            <div
              :class="`filter-dialog-content-option-text ${themeName} ${
                dIndex === 4 && detectionWithinFilterOption.isSelected && !customDate && !isCustomDateSelected ? 'err' : ''
              }`"
              @click="onClickDetectionWithinOption(dIndex)"
            >
              {{ detectionWithinFilterOption.text }}
              <img
                v-if="detectionWithinFilterOption.isSelected && dIndex === 4"
                @click="showCalendar"
                src="@/assets/Calendar_Xsmall_Button-active.svg"
              />
              <img
                v-else-if="!detectionWithinFilterOption.isSelected && dIndex === 4 && $vuetify.theme.dark"
                src="@/assets/Calendar_Xsmall_Button-inactive-dark.svg"
              />
              <img
                v-else-if="!detectionWithinFilterOption.isSelected && dIndex === 4 && !$vuetify.theme.dark"
                src="@/assets/Calendar_Xsmall_Button-inactive.svg"
              />
              <div v-if="dIndex === 4 && detectionWithinFilterOption.isSelected && customDate !== null" :class="`date-text ${themeName}`">
                {{ currentCustomDate }}
              </div>
              <div
                v-else-if="dIndex === 4 && detectionWithinFilterOption.isSelected && !customDate && !isCustomDateSelected"
                :class="`date-text err`"
              >
                Please select the date range.
              </div>
            </div>
          </div>
          <v-dialog v-model="calendarToggle" hide-overlay :content-class="'calendar'" persistent no-click-animation>
            <div class="background-dialog"></div>
            <Calendar
              v-if="calendarToggle"
              @updateCustomDate="updateCustomDate"
              style="transform: translate(5%, -50%)"
              @closeCalendar="closeCalendar"
              :minDate="calendarMinDate"
            />
          </v-dialog>
        </div>

        <!-- Response status -->
        <div :class="`filter-dialog-content-section-title ${themeName}`">Response status</div>
        <div :class="`filter-dialog-content-section-options ${themeName}`" style="margin-bottom: 30px">
          <div
            v-for="(responseStatusFilterOption, rIndex) in responseStatusFilterOptionList"
            :key="responseStatusFilterOption"
            class="filter-dialog-option-container"
            style="margin-right: 0; width: 30%"
          >
            <img
              v-if="$vuetify.theme.dark && responseStatusFilterOption.isSelected"
              src="@/assets/Checkbox-On_16px_Icon_dark.svg"
              width="16px"
              @click="onClickResponseStatusOption(rIndex)"
            />
            <img
              v-else-if="$vuetify.theme.dark && !responseStatusFilterOption.isSelected"
              src="@/assets/Checkbox-Off_16px_2_Icon.svg"
              width="16px"
              @click="onClickResponseStatusOption(rIndex)"
            />
            <img
              v-else-if="responseStatusFilterOption.isSelected"
              src="@/assets/Checkbox-On_16px_Icon_light.svg"
              width="16px"
              @click="onClickResponseStatusOption(rIndex)"
            />
            <img v-else src="@/assets/Checkbox-Off_16px_Icon_light.svg" width="16px" @click="onClickResponseStatusOption(rIndex)" />
            <div :class="`filter-dialog-content-option-text ${themeName}`" style="margin-left: 7px" @click="onClickResponseStatusOption(rIndex)">
              {{ responseStatusFilterOption.text }}
            </div>
          </div>
        </div>
        <!-- Event Type -->
        <div :class="`filter-dialog-content-section-title ${themeName}`" style="margin-bottom: 10px">Event Type</div>
        <div :class="`filter-dialog-content-section-options ${themeName}`" style="flex-wrap: wrap; height: 70px; margin-bottom: 28px">
          <div
            v-for="(eventTypeFilterOption, eIndex) in eventTypeFilterOptionList"
            :key="eventTypeFilterOption"
            class="filter-dialog-option-container"
            style="margin-right: 0; width: 30%"
          >
            <img
              v-if="$vuetify.theme.dark && eventTypeFilterOption.isSelected"
              src="@/assets/Checkbox-On_16px_Icon_dark.svg"
              width="16px"
              @click="onClickEventTypeOption(eIndex)"
            />
            <img
              v-else-if="$vuetify.theme.dark && !eventTypeFilterOption.isSelected"
              src="@/assets/Checkbox-Off_16px_2_Icon.svg"
              width="16px"
              @click="onClickEventTypeOption(eIndex)"
            />
            <img
              v-else-if="eventTypeFilterOption.isSelected"
              src="@/assets/Checkbox-On_16px_Icon_light.svg"
              width="16px"
              @click="onClickEventTypeOption(eIndex)"
            />
            <img v-else src="@/assets/Checkbox-Off_16px_Icon_light.svg" width="16px" @click="onClickEventTypeOption(eIndex)" />
            <div :class="`filter-dialog-content-option-text ${themeName}`" style="margin-left: 7px" @click="onClickEventTypeOption(eIndex)">
              {{ eventTypeFilterOption.text }}
            </div>
          </div>
        </div>
        <!-- PFIRS Data (Prescribed Burns) -->
        <div :class="`filter-dialog-content-section-title ${themeName}`" style="margin-bottom: 10px">PFIRS Data (Prescribed Burns)</div>
        <div :class="`filter-dialog-content-section-options ${themeName}`" style="margin-bottom: 30px">
          <div
            v-for="(pfirsFilterOption, pIndex) in pfirsFilterOptionList"
            :key="pfirsFilterOption"
            class="filter-dialog-option-container"
            @click="onClickPfirsOption(pIndex)"
          >
            <img
              v-if="themeName === 'dark' && pfirsFilterOption.isSelected"
              src="@/assets/Radio_Button_Filled_White.svg"
              height="16"
              width="16"
              class="filter-dialog-content-toggle-image"
            />
            <img
              v-else-if="pfirsFilterOption.isSelected"
              src="@/assets/Radio_Button_Filled_Black.svg"
              height="16"
              width="16"
              class="filter-dialog-content-toggle-image"
            />
            <div v-else :class="`filter-dialog-content-toggle ${themeName}`">
              <!-- <div :class="`filter-dialog-content-toggle-inner ${themeName}`" v-if="pfirsFilterOption.isSelected"></div> -->
            </div>
            <div :class="`filter-dialog-content-option-text ${themeName}`">{{ pfirsFilterOption.text }}</div>
          </div>
        </div>
      </div>
      <div :class="`filter-dialog-bottom ${themeName}`">
        <div :class="`filter-dialog-btn-clear-all ${themeName}`" @click="onClickClearAll()">Reset</div>
        <v-btn class="filter-dialog-btn-apply" color="#FF5B54" height="44" ripple width="108" @click="onClickApplyBtn()">
          <div class="filter-dialog-btn-apply-text">Apply</div>
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import Constants from '@/constants'
import Theme from '@/mixin/theme.mixin'
// TODO: Change Common Component
import Calendar from '@item/calendar/DefaultCalendar'
import moment from 'moment'

export default {
  mixins: [Theme],
  components: { Calendar },
  props: {
    value: Boolean,
    selectedLogInfo: Object,
  },
  watch: {
    value(val) {
      if (val) {
        this.detectionTypeOptionList = [
          {
            isSelected: this.detectionTypeStateValue.allDetection,
            text: 'All Detections',
          },
          {
            isSelected: this.detectionTypeStateValue.firstDetection,
            text: 'First Detections',
          },
        ]
        this.cameraFilterOptionList = [
          {
            isSelected: this.cameraFilterStateValue.allCamera,
            text: 'All cameras',
          },
          {
            isSelected: this.cameraFilterStateValue.alertCamera,
            text: 'Cameras that issued alert',
          },
          {
            isSelected: this.cameraFilterStateValue.noCamera,
            text: 'No cameras',
          },
        ]
        this.detectionWithinFilterOptionList = [
          {
            isSelected: !this.detectionWithinFilterStateValue.isCustom && this.detectionWithinFilterStateValue.selectedHoursFilter === 3,
            text: '3 Hours',
          },
          {
            isSelected: !this.detectionWithinFilterStateValue.isCustom && this.detectionWithinFilterStateValue.selectedHoursFilter === 6,
            text: '6 Hours',
          },
          {
            isSelected: !this.detectionWithinFilterStateValue.isCustom && this.detectionWithinFilterStateValue.selectedHoursFilter === 24,
            text: '24 Hours',
          },
          {
            isSelected: !this.detectionWithinFilterStateValue.isCustom && this.detectionWithinFilterStateValue.selectedHoursFilter === 72,
            text: '72 Hours',
          },
          {
            isSelected: this.detectionWithinFilterStateValue.isCustom,
            text: 'Custom',
          },
        ]
        this.responseStatusFilterOptionList = [
          {
            isSelected: this.responseStatusFilterStateValue.alerted,
            text: 'Alerted',
          },
          {
            isSelected: this.responseStatusFilterStateValue.validated,
            text: 'Validated',
          },
          {
            isSelected: this.responseStatusFilterStateValue.responded,
            text: 'Responded',
          },
        ]
        this.eventTypeFilterOptionList = [
          {
            isSelected: this.eventTypeFilterStateValue.fire,
            text: 'Fire',
          },
          {
            isSelected: this.eventTypeFilterStateValue.nonFire,
            text: 'Non-Fire',
          },
          {
            isSelected: this.eventTypeFilterStateValue.unknown,
            text: 'Unknown',
          },
          {
            isSelected: this.eventTypeFilterStateValue.plannedBurn,
            text: 'Planned Burn',
          },
          {
            isSelected: this.eventTypeFilterStateValue.unselected,
            text: 'Unselected',
          },
        ]
        this.pfirsFilterOptionList = [
          {
            isSelected: this.pfirsDataFilterStateValue.allBurns,
            text: 'All Burns',
          },
          {
            isSelected: this.pfirsDataFilterStateValue.noBurns,
            text: 'No Burns',
          },
        ]
      }
    },
  },
  data() {
    return {
      isCustomDateSelected: true,
      customDate: null,
      calendarToggle: false,
      detectionTypeOptionList: [
        {
          isSelected: true,
          text: 'All Detections',
        },
        {
          isSelected: false,
          text: 'First Detections',
        },
      ],
      cameraFilterOptionList: [
        {
          isSelected: true,
          text: 'All cameras',
        },
        {
          isSelected: false,
          text: 'Cameras that issued alert',
        },
        {
          isSelected: false,
          text: 'No cameras',
        },
      ],
      detectionWithinFilterOptionList: [
        {
          isSelected: false,
          text: '3 Hours',
        },
        {
          isSelected: false,
          text: '6 Hours',
        },
        {
          isSelected: false,
          text: '24 Hours',
        },
        {
          isSelected: true,
          text: '72 Hours',
        },
        {
          isSelected: false,
          text: 'Custom',
        },
      ],
      responseStatusFilterOptionList: [
        {
          isSelected: true,
          text: 'Alerted',
        },
        {
          isSelected: true,
          text: 'Validated',
        },
        {
          isSelected: true,
          text: 'Responded',
        },
      ],
      eventTypeFilterOptionList: [
        {
          isSelected: true,
          text: 'Fire',
        },
        {
          isSelected: true,
          text: 'Non-Fire',
        },
        {
          isSelected: true,
          text: 'Unknown',
        },
        {
          isSelected: true,
          text: 'Planned Burn',
        },
        {
          isSelected: true,
          text: 'Unselected',
        },
      ],
      pfirsFilterOptionList: [
        {
          isSelected: true,
          text: 'All Burns',
        },
        {
          isSelected: false,
          text: 'No Burns',
        },
      ],
    }
  },
  destroyed() {
    this.$store.dispatch('setMapViewFilterAction', Constants.MAP_VIEW_FILTER)
  },
  computed: {
    ...mapState(['camMap', 'mapViewFilter', 'user', 'ArcGISMapInstance']),
    calendarMinDate() {
      const beforeSixMonth = moment().startOf('day').subtract(180, 'days').subtract(1, 'days')
      const contractStartDate = moment(this.user.history_start).subtract(1, 'days')
      const diff = beforeSixMonth.isSameOrAfter(contractStartDate)
      if (diff) return beforeSixMonth
      return contractStartDate
    },
    detectionTypeStateValue() {
      return this.mapViewFilter.detectionType
    },
    cameraFilterStateValue() {
      return this.mapViewFilter.camera
    },
    detectionWithinFilterStateValue() {
      return this.mapViewFilter.detectionWithin
    },
    responseStatusFilterStateValue() {
      return this.mapViewFilter.responseStatus
    },
    eventTypeFilterStateValue() {
      return this.mapViewFilter.eventType
    },
    pfirsDataFilterStateValue() {
      return this.mapViewFilter.pfirsData
    },
    currentCustomDate() {
      if (!this.customDate) return ''
      else {
        if (this.customDate.startDate.isSame(this.customDate.endDate, 'day')) return this.customDate.endDate.format('YYYY-MM-DD')
        return `${this.customDate.startDate.format('YYYY-MM-DD')} ~ ${this.customDate.endDate.format('YYYY-MM-DD')}`
      }
    },
  },
  methods: {
    updateCustomDate(date) {
      this.customDate = date
      this.closeCalendar()
    },
    showCalendar() {
      this.calendarToggle = true
    },
    closeCalendar() {
      this.calendarToggle = false
      if (!this.customDate) this.isCustomDateSelected = false
    },
    hide(isApplyClicked) {
      this.$emit('close-filter-dialog', isApplyClicked)
    },
    onClickDetectionTypeOption(selectedIndex) {
      for (let i = 0; i < this.detectionTypeOptionList.length; i += 1) {
        this.detectionTypeOptionList[i].isSelected = i === selectedIndex
      }
    },
    onClickCameraOption(selectedIndex) {
      for (let i = 0; i < this.cameraFilterOptionList.length; i += 1) {
        this.cameraFilterOptionList[i].isSelected = i === selectedIndex
      }
    },
    onClickDetectionWithinOption(selectedIndex) {
      for (let i = 0; i < this.detectionWithinFilterOptionList.length; i += 1) {
        this.detectionWithinFilterOptionList[i].isSelected = i === selectedIndex
      }
      if (selectedIndex !== 4) {
        this.customDate = null
        this.isCustomDateSelected = true
      }
    },
    onClickResponseStatusOption(selectedIndex) {
      this.responseStatusFilterOptionList[selectedIndex].isSelected = !this.responseStatusFilterOptionList[selectedIndex].isSelected
    },
    onClickEventTypeOption(selectedIndex) {
      this.eventTypeFilterOptionList[selectedIndex].isSelected = !this.eventTypeFilterOptionList[selectedIndex].isSelected
    },
    onClickPfirsOption(selectedIndex) {
      for (let i = 0; i < this.pfirsFilterOptionList.length; i += 1) {
        this.pfirsFilterOptionList[i].isSelected = i === selectedIndex
      }
    },
    onClickClearAll() {
      this.customDate = null
      this.isCustomDateSelected = true
      this.detectionTypeOptionList = [
        {
          isSelected: false,
          text: 'All Detections',
        },
        {
          isSelected: true,
          text: 'First Detections',
        },
      ]
      this.cameraFilterOptionList = [
        {
          isSelected: true,
          text: 'All cameras',
        },
        {
          isSelected: false,
          text: 'Cameras that issued alert',
        },
        {
          isSelected: false,
          text: 'No cameras',
        },
      ]
      this.detectionWithinFilterOptionList = [
        {
          isSelected: false,
          text: '3 Hours',
        },
        {
          isSelected: false,
          text: '6 Hours',
        },
        {
          isSelected: false,
          text: '24 Hours',
        },
        {
          isSelected: true,
          text: '72 Hours',
        },
        {
          isSelected: false,
          text: 'Custom',
        },
      ]
      this.responseStatusFilterOptionList = [
        {
          isSelected: true,
          text: 'Alerted',
        },
        {
          isSelected: true,
          text: 'Validated',
        },
        {
          isSelected: true,
          text: 'Responded',
        },
      ]
      this.eventTypeFilterOptionList = [
        {
          isSelected: true,
          text: 'Fire',
        },
        {
          isSelected: true,
          text: 'Non-Fire',
        },
        {
          isSelected: true,
          text: 'Unknown',
        },
        {
          isSelected: true,
          text: 'Planned Burn',
        },
        {
          isSelected: true,
          text: 'Unselected',
        },
      ]
      this.pfirsFilterOptionList = [
        {
          isSelected: true,
          text: 'All Burns',
        },
        {
          isSelected: false,
          text: 'No Burns',
        },
      ]
    },
    async onClickApplyBtn() {
      const selectedDetectionOption = this.detectionWithinFilterOptionList.filter((option) => option.isSelected)[0].text
      if (selectedDetectionOption === 'Custom' && !this.customDate) {
        this.isCustomDateSelected = false
        return
      }
      const { camera, isCameraDefault } = this.checkCameraType()
      const { detectionWithin, isDetectionWithinDefault } = this.checkDetectionWithin()
      const { responseStatus, isResponseStatusDefault } = this.checkResponseStatus()
      const { eventType, isEventTypeDefault } = this.checkEventType()
      const { pfirsData, isPfirsDataDefault } = this.checkPfirsData()
      const { detectionType, isDetectionTypeDefault } = this.checkDetectionType()

      await this.$store.dispatch('setIsLogListLoadedAction', false)
      if (
        isCameraDefault &&
        isDetectionWithinDefault &&
        isResponseStatusDefault &&
        isEventTypeDefault &&
        isPfirsDataDefault &&
        isDetectionTypeDefault
      ) {
        this.$emit('onFilterCheck', false)
      } else {
        this.$emit('onFilterCheck', true)
      }
      this.hide(true)
      if (selectedDetectionOption === 'Custom' && this.customDate) {
        const startDate = this.customDate.startDate.clone().startOf('day').format()
        const endDate = this.customDate.endDate.clone().endOf('day').format()
        this.$store.dispatch('getLogListByPeriodInMapview', {
          inChild: true,
          inNoti: true,
          inRepOrg: true,
          inRespStat: true,
          tabIndex: 1,
          startDate,
          endDate,
        })
      } else {
        this.customDate = null
      }
      this.$store.dispatch('setMapViewFilterAction', { detectionType, camera, detectionWithin, responseStatus, eventType, pfirsData })
      this.updatePfirsLayerParams()
    },
    async updatePfirsLayerParams() {
      const startDate = this.detectionWithinFilterStateValue?.dateObj?.startDate
        ? this.detectionWithinFilterStateValue?.dateObj?.startDate.startOf('day').format()
        : this.detectionWithinFilterStateValue?.dateObj?.format()
      const endDate = this.detectionWithinFilterStateValue?.dateObj?.endDate
        ? this.detectionWithinFilterStateValue?.dateObj?.endDate.endOf('day').format()
        : moment().format()
      const pfirsGeoJsonLayer = this.ArcGISMapInstance.findLayerById('alchera-mapview-map-pfirs-geojsonlayer')
      pfirsGeoJsonLayer.customParameters.start_date = startDate
      pfirsGeoJsonLayer.customParameters.end_date = endDate
      pfirsGeoJsonLayer.refresh()
    },
    checkDetectionType() {
      const allDetection = this.detectionTypeOptionList[0].isSelected
      const firstDetection = this.detectionTypeOptionList[1].isSelected
      return { detectionType: { allDetection, firstDetection }, isDetectionTypeDefault: firstDetection }
    },
    checkCameraType() {
      let isCameraDefault = false
      const allCamera = this.cameraFilterOptionList[0].isSelected
      const alertCamera = this.cameraFilterOptionList[1].isSelected
      const noCamera = this.cameraFilterOptionList[2].isSelected
      if (allCamera && !alertCamera && !noCamera) isCameraDefault = true
      return { camera: { allCamera, alertCamera, noCamera }, isCameraDefault }
    },
    checkDetectionWithin() {
      const selectedDetectionOption = this.detectionWithinFilterOptionList.filter((option) => option.isSelected)[0]
      let currentTime = moment()
      let isCustom = false
      let isDetectionWithinDefault = false
      let selectedHoursFilter = 72
      switch (selectedDetectionOption.text) {
        case '3 Hours':
          currentTime.subtract(3, 'hours')
          selectedHoursFilter = 3
          break
        case '6 Hours':
          currentTime.subtract(6, 'hours')
          selectedHoursFilter = 6
          break
        case '24 Hours':
          currentTime.subtract(24, 'hours')
          selectedHoursFilter = 24
          break
        case '72 Hours':
          currentTime.subtract(72, 'hours')
          isDetectionWithinDefault = true
          break
        case 'Custom':
          currentTime = { startDate: this.customDate.startDate.clone(), endDate: this.customDate.endDate.clone() }
          isCustom = true
          break
      }
      return { detectionWithin: { dateObj: currentTime, isCustom, selectedHoursFilter }, isDetectionWithinDefault }
    },
    checkResponseStatus() {
      const alerted = this.responseStatusFilterOptionList[0].isSelected
      const validated = this.responseStatusFilterOptionList[1].isSelected
      const responded = this.responseStatusFilterOptionList[2].isSelected
      let isResponseStatusDefault = false
      if (alerted && validated && responded) isResponseStatusDefault = true
      return { responseStatus: { alerted, validated, responded }, isResponseStatusDefault }
    },
    checkEventType() {
      const fire = this.eventTypeFilterOptionList[0].isSelected
      const nonFire = this.eventTypeFilterOptionList[1].isSelected
      const unknown = this.eventTypeFilterOptionList[2].isSelected
      const plannedBurn = this.eventTypeFilterOptionList[3].isSelected
      const unselected = this.eventTypeFilterOptionList[4].isSelected
      let isEventTypeDefault = false
      if (fire && nonFire && unknown && plannedBurn && unselected) isEventTypeDefault = true
      return { eventType: { fire, nonFire, unknown, plannedBurn, unselected }, isEventTypeDefault }
    },
    checkPfirsData() {
      let isPfirsDataDefault = false
      const allBurns = this.pfirsFilterOptionList[0].isSelected
      const noBurns = this.pfirsFilterOptionList[1].isSelected
      if (allBurns && !noBurns) isPfirsDataDefault = true
      return { pfirsData: { allBurns, noBurns }, isPfirsDataDefault }
    },
  },
}
</script>

<style lang="scss" scoped>
.filter-dialog-card {
  border-radius: 16px;
  width: 600px;
  height: 610px;
  .filter-dialog-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    height: 65px;
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: #363636;
    &.dark {
      background-color: #25262c;
      color: #ffffffde;
    }
  }
  .filter-dialog-close {
    position: absolute;
    top: 25px;
    right: 30px;
    cursor: pointer;
  }
  .filter-dialog-content-container {
    height: 474px;
    overflow-y: scroll;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
    background-color: #ffffff;
    border-top: 1px solid #f9f9f9;
    border-bottom: 1px solid #f9f9f9;

    &.dark::-webkit-scrollbar-thumb {
      background-color: rgba(255, 255, 255, 0.87);
    }
    &::-webkit-scrollbar-thumb {
      background-color: #00000042;
      border-radius: 3px;
    }
    &::-webkit-scrollbar {
      background-color: rgba(255, 255, 255, 0.87);
      width: 6px;
    }
    &.dark::-webkit-scrollbar {
      background-color: var(--f-bg-middle);
    }

    &.dark {
      background-color: #25262c;
      border-top: 1px solid #323340;
      border-bottom: 1px solid #323340;
    }

    .filter-dialog-content-top-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin: 10px 0;
      color: #363636;
      &.dark {
        color: #ffffffde;
      }
    }

    .filter-dialog-content-section-title {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      padding-left: 10px;
      margin-bottom: 15px;
      color: #363636;
      &.dark {
        color: #ffffffde;
      }
    }

    .filter-dialog-content-section-options {
      width: 100%;
      padding-left: 20px;
      display: flex;

      .filter-dialog-option-container {
        display: flex;
        align-items: center;
        margin-right: 30px;

        .filter-dialog-option-container-img-container {
          height: 19px;
          width: 19px;
          margin-right: 7px;
          display: flex;
          justify-content: center;
          align-items: center;
        }

        .filter-dialog-content-toggle {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 7px;
          padding: 0;
          height: 16px;
          width: 16px;
          line-height: 16px;
          border-radius: 50%;
          border: 2px solid #363636;
          cursor: pointer;
          &.dark {
            border: 2px solid #ffffffde;
          }
          &.err {
            border: 2px solid var(--f-primary-90);
          }

          .filter-dialog-content-toggle-inner {
            height: 8px;
            width: 8px;
            line-height: 8px;
            margin: 0;
            border-radius: 50%;
            background-color: #363636;
            &.dark {
              background-color: #ffffffde;
            }
            &.err {
              background-color: var(--f-primary-90);
            }
          }
        }

        img {
          cursor: pointer;
        }

        .filter-dialog-content-option-text {
          font-weight: 400;
          font-size: 14px;
          color: #363636;
          display: flex;
          align-items: center;
          cursor: pointer;

          &.dark {
            color: #ffffffde;
          }
          &.err {
            color: var(--f-primary-90);
          }
          img {
            margin-left: 8px;
          }
        }
      }
    }
  }

  .filter-dialog-bottom {
    height: 70px;
    width: 600px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 20px;
    &.dark {
      background-color: #25262c;
    }
    .filter-dialog-btn-clear-all {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #363636;
      &.dark {
        color: #ffffffde;
      }
    }

    .filter-dialog-btn-apply {
      border-radius: 8px;
      .filter-dialog-btn-apply-text {
        letter-spacing: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        color: #ffffffde;
      }
    }
  }
}

.calendar-wrapper {
  position: absolute;
  transform: translate(280px, -150px);
  z-index: 1;
}

.date-text {
  font-weight: 500;
  font-size: 12px;
  height: 18px;
  color: #00000042;
  position: absolute;
  width: 200%;
  bottom: -20px;
  &.err {
    color: var(--f-primary-90);
  }
  &.dark {
    color: #ffffff99;
  }
}

.background-dialog {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.filter-dialog-content-toggle-image {
  margin-right: 7px;
  cursor: pointer;
}
</style>
