<template>
  <v-dialog v-model="value" @keydown.esc="hide" width="1005" persistent no-click-animation>
    <v-card :class="`live-view-dialog-card ${themeName}`" data-testid="DetectionClip_Modal">
      <div :class="`live-view-dialog-title ${themeName}`">
        <div v-if="providerLogoUrl" class="detection-clip-camera-provider">
          Cameras by
          <img :src="providerLogoUrl" width="113px" style="margin-left: 7px" />
        </div>
        Detection Clip
      </div>
      <div class="live-view-dialog-close" @click="hide" data-testid="Close_Button">
        <img v-if="$vuetify.theme.dark" src="@/assets/Exit_Icon.svg" width="16px" />
        <img v-else src="@/assets/Exit_Icon_Black.svg" width="15px" />
      </div>
      <div style="height: 613px; width: 1005px; padding: 0 40px 20px 40px">
        <ImagePlayer v-if="imagePlayerLoad" :currentLog="mapViewSelectedLog">
          <template v-slot="{ currentIndex, moveFrame, playerState, imageUrlList, detectedImageIndex, hasAfterImages, play, pause, currentLogId }">
            <FramePlayerBar
              :currentLogId="currentLogId"
              :currentIndex="currentIndex"
              @moveFrame="moveFrame"
              :playerState="playerState"
              :imageUrlList="imageUrlList"
              :detectedImageIndex="detectedImageIndex"
              :hasAfterImages="hasAfterImages"
              @play="play"
              @pause="pause"
            />
          </template>
        </ImagePlayer>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapState } from 'vuex'
import Theme from '@/mixin/theme.mixin'
import ImagePlayer from '@common/newImage/ImagePlayer'
import FramePlayerBar from '@common/newImage/FramePlayerBar'
import Util from '@/util'

export default {
  mixins: [Theme],
  components: { ImagePlayer, FramePlayerBar },
  props: {
    value: Boolean,
  },
  data() {
    return {
      loading: false,
      loadedImages: [],
      currentFrameIndex: 0,
      frameIndex: 0,
      play: 'stop',
      playing: false,
      didPlay: false,
      frameMode: '',
      currentCamImageSrc: '',
      timeoutSetCamImageSrc: null,
      canvasActiveOption: {
        selectable: false,
        wheel: false,
        dragLeftButton: true,
      },
      imagePlayerLoad: false,
    }
  },
  mounted() {
    this.imagePlayerLoad = true
  },
  computed: {
    ...mapState(['mapViewSelectedLog']),
    providerLogoUrl() {
      if (this.mapViewSelectedLog.provider_dark_logo_path || this.mapViewSelectedLog.provider_light_logo_path) {
        const currentLogoUrl =
          this.themeName === 'dark' ? this.mapViewSelectedLog.provider_dark_logo_path : this.mapViewSelectedLog.provider_light_logo_path

        return Util.getImageUrl(currentLogoUrl)
      }

      return null
    },
  },
  methods: {
    hide() {
      this.$emit('close')
    },
  },
}
</script>

<style lang="scss" scoped>
.live-view-dialog-card {
  .live-view-dialog-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #363636;
    &.dark {
      color: #ffffffde;
    }

    .detection-clip-camera-provider {
      display: flex;
      align-items: center;
      font-size: 13px;
      font-weight: 400;
      position: absolute;
      top: 25px;
      left: 40px;
      cursor: pointer;
    }
  }
  .live-view-dialog-close {
    position: absolute;
    top: 25px;
    right: 30px;
    cursor: pointer;
  }
}

.live-view-image-player-container {
  height: 100%;
}
</style>
