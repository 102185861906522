<template>
  <div class="map-view-map">
    <div id="viewDiv" class="balt-theme"></div>
    <!-- <div id="elevationDiv" class="esri-widget">
      <label>Elevation: <input id="elevationInput" type="checkbox" checked="yes" /> </label>
    </div> -->
    <div id="topbar">
      <button
        class="directions-button"
        :class="{
          active: showDirectionsPopup,
        }"
        id="directionsButton"
        type="button"
        title="Show directions UI"
        @mouseover="onEventWidgetButton('mouseover', 'directionsButton')"
        @mouseleave="onEventWidgetButton('mouseleave', 'directionsButton')"
      >
        <img :src="require('@/assets/Icon_Driving_Distance.svg')" v-show="!directionsButtonActive" />
        <img :src="require('@/assets/Icon_Driving_Distance_white.svg')" v-show="directionsButtonActive" />
      </button>
      <button class="action-button esri-icon-measure-line" id="distanceButton" type="button" title="Measure distance between two points"></button>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import util from '@/util'
import { loadModules } from 'esri-loader'
import moment from 'moment'
import Constants from '@/constants'
import Theme from '@/mixin/theme.mixin'
import MapviewToastContent from '@/components/common/MapviewToastContent'
import { auth } from '@/api'

export default {
  mixins: [Theme],
  component: { MapviewToastContent },
  props: {
    /**
     * emit events
     * cardinal-direction
     */
    currentLog: Object,
  },
  data() {
    return {
      refreshGraphicsInterval: null,
      // cameraIcon: 'CameraPointer_Icon.svg',
      cameraIcon: 'CurrentLocation_Filled_40px_Icon.svg',
      // cameraIconNonDirection: 'Target_SolidBlack_Icon.svg',
      cameraIconNonDirection: 'CameraLocation_Filled_24px_Icon.svg',
      customGeoJsonDataIcon: 'PlannedBurn_Button.svg',
      selectedLogFireIcon: 'Exclamation_Mark_White.svg',
      arcgisMap: null,
      sceneview: null,
      rotate: 0,
      isCenteredToFirstLog: false,
      // graphics
      graphicsList: [],
      // graphics UIDs
      // clickableGraphicUIDList: [],
      // camera graphics geojson layer
      camerasDataGeoJsonLayer: null,
      // pfirs data feature layer
      pfirsDataFeatureLayer: null,
      // log graphics (points) layer
      logListGeoJsonPointDataLayer: null,
      // log graphics (lines) layer
      logListGeoJsonLineDataLayer: null,
      // custom date range log graphics (points) layer
      customDateRangeLogListGeoJsonPointDataLayer: null,
      // custom date range log graphics (lines) layer
      customDateRangeLogListGeoJsonLineDataLayer: null,
      cameraFilterIdList: [],
      // UI button variables
      directionsButtonActive: false,
      showDirectionsPopup: false,
    }
  },
  computed: {
    ...mapState(['camList', 'camMap', 'recentLogList', 'logListv2', 'mapViewFilter']),
    organizationIds() {
      return this.camList.map((c) => c.id).join(',')
    },
    latitude() {
      return this.currentLog && this.camMap[this.currentLog.camera_id]?.coordinate && this.camMap[this.currentLog.camera_id].coordinate.split(' ')[0]
    },
    longitude() {
      return this.currentLog && this.camMap[this.currentLog.camera_id]?.coordinate && this.camMap[this.currentLog.camera_id].coordinate.split(' ')[1]
    },
    degreeString() {
      return util.locaitonToDegree(this.degree)
    },
    degree() {
      return this.currentLog && this.currentLog.direction
    },
    nonDirection() {
      return this.degree === -1
    },
    filteredRecentLogList() {
      const detectionTypeFilterObj = this.mapViewFilter.detectionType
      const detectionWithinFilterObj = this.mapViewFilter.detectionWithin
      const responseStatusFilterObj = this.mapViewFilter.responseStatus
      const eventTypeFilterObj = this.mapViewFilter.eventType

      const list = detectionWithinFilterObj.isCustom ? this.logListv2 : this.recentLogList

      return list.filter((log) => {
        const isDuplicateLog = !Object.keys(log).includes('is_checked_organization')

        const detectionType = this.detectionTypeFilterMethod(log, detectionTypeFilterObj)
        const detectionWithin = this.detectionWithinFilterMethod(log, detectionWithinFilterObj)
        const responseStatus = isDuplicateLog || this.responseStatusFilterMethod(log, responseStatusFilterObj)
        const eventType = isDuplicateLog || this.eventTypeFilterMethod(log, eventTypeFilterObj)

        return detectionType && detectionWithin && responseStatus && eventType && !!this.camMap[log.camera_id]
      })
    },
    isCustomDateFilter() {
      return this.mapViewFilter.detectionWithin.isCustom
    },
    recentLogListLogIDs() {
      if (!this.filteredRecentLogList || !this.filteredRecentLogList.length) return []
      return this.filteredRecentLogList.map((log) => log.log_id.toString())
    },
    recentLogListIDs() {
      if (!this.filteredRecentLogList || !this.filteredRecentLogList.length) return []
      return this.filteredRecentLogList.map((log) => log.camera_id.toString())
    },
    // filters
    showOnlyFirstDetectionLogs() {
      return this.mapViewFilter.detectionType.firstDetection
    },
    cameraFilter() {
      if (!this.mapViewFilter) return ''
      if (this.mapViewFilter.camera.alertCamera) {
        return 'alert-only'
      } else if (this.mapViewFilter.camera.noCamera) {
        return 'none'
      }
      return 'all'
    },
    detectionWithinFilter() {
      if (!this.mapViewFilter) return {}
      return this.mapViewFilter.detectionWithin
    },
    responseStatusFilter() {
      if (!this.mapViewFilter) return []
      let result = []
      if (this.mapViewFilter.responseStatus.alerted) {
        result.push('alerted')
      }
      if (this.mapViewFilter.responseStatus.validated) {
        result.push('validated')
      }
      if (this.mapViewFilter.responseStatus.responded) {
        result.push('responded')
      }
      return result
    },
    eventTypeFilter() {
      if (!this.mapViewFilter) return []
      let result = []
      if (this.mapViewFilter.eventType.unselected) {
        result.push(1)
      }
      if (this.mapViewFilter.eventType.fire) {
        result.push(2)
      }
      if (this.mapViewFilter.eventType.nonFire) {
        result.push(3)
      }
      if (this.mapViewFilter.eventType.unknown) {
        result.push(4)
      }
      if (this.mapViewFilter.eventType.plannedBurn) {
        result.push(5)
      }
      return result
    },
    pfirsFilter() {
      if (!this.mapViewFilter) return ''
      if (this.mapViewFilter.pfirsData.noBurns) {
        return 'none'
      }
      return 'all'
    },
  },
  watch: {
    currentLog(val, prevVal) {
      if (val && val.camera_id && this.sceneview) {
        this.centerMapToCurrentLog()
        if (!prevVal && !!val && val.created_at) {
          this.sceneview.graphics.removeAll()
          this.drawCircleGraphic()
          // this.drawExclamationMarkIcon()
        }
      }
      if (!val && prevVal) {
        this.sceneview.graphics.removeAll()
      }
    },
    recentLogList(val) {
      if (val.length && !this.isCenteredToFirstLog) {
        this.centerMapToFirstLog()
      }
    },
    mapViewFilter() {
      // filter cameras geoJsonLayer
      this.applyCameraFilter(this.cameraFilter)
      // filter pfirs icons
      this.applyPfirsFilter(this.pfirsFilter)
      // filter point/line log graphics
      this.applyLogGraphicsFilter(this.cameraFilter)
      // toggle GeoJsonLayers' visibility whether custom date is selected or not
      this.handleCustomDateFilter()
    },
  },
  async mounted() {
    await this.getCamList()
    this.loadMap()
  },
  destroyed() {
    clearTimeout(this.refreshGraphicsInterval)
    this.$store.commit('setArcGISMapInstance', null)
  },
  methods: {
    ...mapActions(['getCamList']),
    async loadMap() {
      loadModules(
        [
          'esri/config',
          'esri/Map',
          'esri/renderers/ClassBreaksRenderer',
          'esri/views/SceneView',
          'esri/widgets/BasemapToggle',
          'esri/widgets/Search',
          'esri/widgets/Zoom',
          'esri/widgets/NavigationToggle',
          'esri/widgets/Compass',
          'esri/widgets/DirectLineMeasurement3D',
          'esri/widgets/Directions',
          'esri/widgets/CoordinateConversion',
          'esri/layers/GraphicsLayer',
          'esri/layers/GeoJSONLayer',
          'esri/layers/RouteLayer',
        ],
        {
          css: true,
        }
      ).then(
        async ([
          esriConfig,
          Map,
          ClassBreaksRenderer,
          SceneView,
          BasemapToggle,
          Search,
          Zoom,
          NavigationToggle,
          Compass,
          DirectLineMeasurement3D,
          Directions,
          CoordinateConversion,
          GraphicsLayer,
          GeoJSONLayer,
          RouteLayer,
        ]) => {
          const self = this
          const token = await auth.getToken()

          const buildEnvURL = process.env.VUE_APP_API_URL
          const fillerBackslashCharacter = buildEnvURL[buildEnvURL.length - 1] === '/' ? '' : '/'
          const urlPrependString = buildEnvURL + fillerBackslashCharacter

          // set API key
          esriConfig.apiKey = Constants.ARCGIS_API_KEY

          // set auth token in header
          esriConfig.request.interceptors.push({
            urls: urlPrependString + 'camera',
            headers: {
              Authorization: `Bearer ${token}`,
            },
          })
          esriConfig.request.interceptors.push({
            urls: urlPrependString + 'map/log/recent',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            after: function (response) {
              if (response.requestOptions.query.type === 'point' && !response.data.features.length) {
                response.data.name = 'PointsForRecentLogs'
                response.data.features = [Constants.ARCGIS_POINT_DEFAULT]
              } else if (response.requestOptions.query.type === 'line' && !response.data.features.length) {
                response.data.name = 'LinesForRecentLogs'
                response.data.features = [Constants.ARCGIS_LINE_DEFAULT]
              }
            },
          })
          esriConfig.request.interceptors.push({
            urls: urlPrependString + 'map/log/all',
            headers: {
              Authorization: `Bearer ${token}`,
            },
            after: function (response) {
              if (response.requestOptions.query.type === 'point' && !response.data.features.length) {
                response.data.name = 'PointsForRecentLogs'
                response.data.features = [Constants.ARCGIS_POINT_DEFAULT]
              } else if (response.requestOptions.query.type === 'line' && !response.data.features.length) {
                response.data.name = 'LinesForRecentLogs'
                response.data.features = [Constants.ARCGIS_LINE_DEFAULT]
              }
            },
          })

          // create map with the given options
          const map = new Map({
            basemap: 'arcgis-topographic',
            ground: 'world-elevation',
          })

          this.$store.commit('setArcGISMapInstance', map)

          // Sunnyvale, CA coordinates
          let x = -122.036346
          let y = 37.368832
          if (this.filteredRecentLogList.length) {
            const firstLog = this.filteredRecentLogList[0]
            if (!!firstLog.longitude && firstLog.longitude !== -1 && !!firstLog.latitude && firstLog.latitude !== -1) {
              x = firstLog.longitude
              y = firstLog.latitude
            } else {
              const firstLogCameraCoordinates = this.camMap[firstLog.camera_id].coordinate
              x = Number(firstLogCameraCoordinates.split(' ')[1])
              y = Number(firstLogCameraCoordinates.split(' ')[0])
            }
          }

          // assign map to this view
          const view = new SceneView({
            container: this.$el,
            map,
            camera: {
              position: {
                // center map on first load on Sunnyvale: [37.368832, -122.036346]
                x, // Longitude
                y, // Latitude
                z: 40000,
              },
              // tilt: 75
            },
            zoom: 12,
            constraints: {
              altitude: {
                max: 2600000,
              },
            },
            navigation: {
              mouseWheelZoomEnabled: false,
            },
          })

          this.sceneview = view

          view.ui.components = ['attribution']

          // add basemap toggle
          const basemapToggle = new BasemapToggle({
            view,
            nextBaseMap: 'arcgis-navigation',
          })
          view.ui.add(basemapToggle, 'bottom-right')

          // add distance measurement widget button container
          view.ui.add('topbar', 'top-right')

          // add zoom/navigationToggle/Compass widgets
          const zoomWidget = new Zoom({
            view,
          })
          const navigationToggle = new NavigationToggle({
            view,
          })
          const compassWidget = new Compass({
            view,
          })
          view.ui.add([zoomWidget, navigationToggle, compassWidget], 'top-right')

          // add coordinate conversion widget
          const coordinateConversionWidget = new CoordinateConversion({
            view,
          })
          view.ui.add(coordinateConversionWidget, 'bottom-right')

          const routeLayer = new RouteLayer()

          // add distance measurement widget
          let activeWidget = null

          function setActiveWidget(type) {
            switch (type) {
              case 'directions':
                activeWidget = new Directions({
                  layer: routeLayer,
                  apiKey: esriConfig.apiKey,
                  view,
                })

                view.ui.add(activeWidget, 'top-left')
                setActiveButton(document.getElementById('directionsButton'))
                break
              case 'distance':
                activeWidget = new DirectLineMeasurement3D({
                  view,
                })

                // skip the initial 'new measurement' button
                activeWidget.viewModel.start().catch((error) => {
                  if (promiseUtils.isAbortError(error)) {
                    return // don't display abort errors
                  }
                  throw error // throw other errors since they are of interest
                })

                view.ui.add(activeWidget, 'top-left')
                setActiveButton(document.getElementById('distanceButton'))
                self.directionsButtonActive = false
                break
              case null:
                if (activeWidget) {
                  view.ui.remove(activeWidget)
                  activeWidget.destroy()
                  activeWidget = null
                  self.directionsButtonActive = false
                }
                break
            }
          }

          function setActiveButton(selectedButton) {
            // focus the view to activate keyboard shortcuts for sketching
            view.focus()
            const elements = document.getElementsByClassName('active')
            for (let i = 0; i < elements.length; i++) {
              elements[i].classList.remove('active')
            }
            if (selectedButton) {
              selectedButton.classList.add('active')
            }
          }

          document.getElementById('directionsButton').addEventListener('click', () => {
            setActiveWidget(null)
            self.showDirectionsPopup = !self.showDirectionsPopup
            if (self.showDirectionsPopup) {
              setActiveWidget('directions')
            } else {
              setActiveWidget(null)
            }
          })

          document.getElementById('distanceButton').addEventListener('click', (event) => {
            setActiveWidget(null)
            self.showDirectionsPopup = false
            if (!event.target.classList.contains('active')) {
              setActiveWidget('distance')
            } else {
              setActiveButton(null)
            }
          })

          // const measurementWidget = new DirectLineMeasurement3D({
          //   view,
          //   unit: 'kilometers',
          //   // unitOptions: ['kilometers'],
          // })
          // view.ui.add(measurementWidget, 'top-left')

          // PFIRS fire locations data
          // custom geoJSON data renderer
          const pfirsGeoJsonDataRenderer = {
            type: 'simple',
            symbol: {
              type: 'picture-marker',
              url: this.customGeoJsonDataIcon,
              width: '32px',
              height: '32px',
            },
          }

          // custom geoJSON data labels
          const pfirsGeoJsonDataLabels = {
            symbol: {
              type: 'text',
              color: '#FFFFFF',
              haloColor: '#5E8D74',
              haloSize: '2px',
              font: {
                size: '12px',
                family: 'Noto Sans',
                style: 'italic',
                weight: 'normal',
              },
            },
          }

          const pfirsFirePopupTemplate = {
            title: '{BurnName} (PFIRS)',
            outFields: ['*'],
            content: [
              {
                type: 'custom',
                creator: (item) => {
                  const longitude =
                    typeof item.graphic.geometry.longitude === 'number'
                      ? item.graphic.geometry.longitude.toFixed(6)
                      : item.graphic.geometry.longitude.toString()
                  const latitude =
                    typeof item.graphic.geometry.latitude === 'number'
                      ? item.graphic.geometry.latitude.toFixed(6)
                      : item.graphic.geometry.latitude.toString()
                  return `
                <table class="esri-widget__table">
                  <tbody>
                    <tr>
                      <th class="esri-feature-fields__field-header">Lat</th>
                      <td class="esri-feature-fields__field-data">${latitude}</td>
                    </tr>
                    <tr>
                      <th class="esri-feature-fields__field-header">Long</th>
                      <td class="esri-feature-fields__field-data">${longitude}</td>
                    </tr>
                  </tbody>
                </table>`
                },
              },
              {
                type: 'fields',
                fieldInfos: [
                  {
                    fieldName: 'BurnDate',
                    label: 'Burn Date',
                  },
                  {
                    fieldName: 'BurnType',
                    label: 'Burn Type',
                  },
                  {
                    fieldName: 'Acres',
                    label: 'Acres',
                  },
                  {
                    fieldName: 'ManagingAgency',
                    label: 'Managing Agency',
                  },
                  {
                    fieldName: 'LastUpdated',
                    label: 'Last Updated',
                  },
                ],
              },
            ],
            overwriteActions: true,
            returnGeometry: true,
          }

          // add geoJSON layer (geoJSON data from ArcGIS Developer Dashboard)
          let start_date = this.mapViewFilter.detectionWithin.dateObj?.format()
          let end_date = moment().format()

          const pfirsGeoJsonDataLayer = new GeoJSONLayer({
            id: 'alchera-mapview-map-pfirs-geojsonlayer',
            url: urlPrependString + `external/prescribed-burn`,
            renderer: pfirsGeoJsonDataRenderer,
            labelingInfo: [pfirsGeoJsonDataLabels],
            popupTemplate: pfirsFirePopupTemplate,
            customParameters: {
              start_date,
              end_date,
            },
          })
          this.pfirsDataFeatureLayer = pfirsGeoJsonDataLayer
          map.add(pfirsGeoJsonDataLayer)

          // camera locations feature layer
          // custom geoJSON data renderer
          const camerasGeoJsonDataRenderer = {
            type: 'simple',
            symbol: {
              type: 'picture-marker',
              url: this.cameraIconNonDirection,
              width: '24px',
              height: '24px',
            },
          }

          // custom geoJSON data labels
          const camerasGeoJsonDataLabels = {
            symbol: {
              type: 'text',
              color: '#FFFFFF',
              haloColor: '#5E8D74',
              haloSize: '2px',
              font: {
                size: '12px',
                family: 'Noto Sans',
                style: 'italic',
                weight: 'normal',
              },
            },
          }

          // define "go to" action
          const goToAction = {
            title: 'Go To',
            id: 'go-to-action',
            className: 'esri-icon-link-external',
          }
          const camerasPopupTemplate = {
            title: '{name}',
            outFields: ['altitude'],
            content: [
              {
                type: 'custom',
                creator: (item) => {
                  const longitude =
                    typeof item.graphic.geometry.longitude === 'number'
                      ? item.graphic.geometry.longitude.toFixed(6)
                      : item.graphic.geometry.longitude.toString()
                  const latitude =
                    typeof item.graphic.geometry.latitude === 'number'
                      ? item.graphic.geometry.latitude.toFixed(6)
                      : item.graphic.geometry.latitude.toString()
                  return `
                <table class="esri-widget__table">
                  <tbody>
                    <tr>
                      <th class="esri-feature-fields__field-header">Lat</th>
                      <td class="esri-feature-fields__field-data">${latitude}</td>
                    </tr>
                    <tr>
                      <th class="esri-feature-fields__field-header">Long</th>
                      <td class="esri-feature-fields__field-data">${longitude}</td>
                    </tr>
                  </tbody>
                </table>`
                },
              },
              {
                type: 'fields',
                fieldInfos: [
                  {
                    fieldName: 'altitude',
                    label: 'Altitude',
                  },
                ],
              },
            ],
            actions: [goToAction],
            overwriteActions: true,
            returnGeometry: true,
          }

          const camerasDataGeoJsonLayer = new GeoJSONLayer({
            url: urlPrependString + 'camera?mode=geojson',
            renderer: camerasGeoJsonDataRenderer,
            outFields: ['*'],
            labelingInfo: [camerasGeoJsonDataLabels],
            popupTemplate: camerasPopupTemplate,
          })
          this.camerasDataGeoJsonLayer = camerasDataGeoJsonLayer
          map.add(camerasDataGeoJsonLayer)

          // RECENT LOG LIST GEOJSON: START

          // point symbols
          const lessThanThreeHoursPointSymbol = {
            type: 'simple-marker',
            color: '#F9423A',
            outline: {
              color: '#F9423A',
              width: 10,
            },
          }
          const lessThanSixHoursPointSymbol = {
            type: 'simple-marker',
            color: '#FFB800',
            outline: {
              color: '#FFB800',
              width: 10,
            },
          }
          const lessThanOneDayPointSymbol = {
            type: 'simple-marker',
            color: '#384CFF',
            outline: {
              color: '#384CFF',
              width: 10,
            },
          }
          const extraPointSymbol = {
            type: 'simple-marker',
            color: '#6D6D6D',
            outline: {
              color: '#6D6D6D',
              width: 10,
            },
          }
          // line symbols
          const lessThanThreeHoursLineSymbol = {
            type: 'simple-line',
            color: '#F9423A',
            width: 2,
          }

          const lessThanSixHoursLineSymbol = {
            type: 'simple-line',
            color: '#FFB800',
            width: 2,
          }

          const lessThanOneDayLineSymbol = {
            type: 'simple-line',
            color: '#384CFF',
            width: 2,
          }

          const extraLineSymbol = {
            type: 'simple-line',
            color: '#6D6D6D',
            width: 2,
          }

          // recent logs fire locations data

          // custom geoJSON points data renderer
          const recentLogGeoJsonPointDataRenderer = new ClassBreaksRenderer({
            // field: 'created_at',
            valueExpression: "DateDiff($feature.called_time , Date($feature.created_at), 'seconds' )",
            defaultLabel: 'Default',
            defaultSymbol: {
              type: 'simple-marker',
              // color: '#6D6D6D',
              color: 'pink',
              outline: {
                // color: '#6D6D6D',
                color: 'pink',
                width: 10,
              },
            },
            classBreakInfos: [
              {
                minValue: 0,
                maxValue: 60 * 60 * 3 - 1,
                symbol: lessThanThreeHoursPointSymbol,
                label: '0 ~ 3 Hours',
              },
              {
                minValue: 60 * 60 * 3,
                maxValue: 60 * 60 * 6 - 1,
                symbol: lessThanSixHoursPointSymbol,
                label: '3 ~ 6 Hours',
              },
              {
                minValue: 60 * 60 * 6,
                maxValue: 60 * 60 * 24 - 1,
                symbol: lessThanOneDayPointSymbol,
                label: '6 ~ 24 Hours',
              },
              {
                minValue: 60 * 60 * 24,
                maxValue: 60 * 60 * 24 * 31 * 12,
                symbol: extraPointSymbol,
                label: 'Extra',
              },
            ],
          })

          // custom geoJSON lines data renderer
          const recentLogGeoJsonLineDataRenderer = new ClassBreaksRenderer({
            // field: 'created_at',
            valueExpression: "DateDiff($feature.called_time , Date($feature.created_at), 'seconds' )",
            defaultLabel: 'Default',
            defaultSymbol: {
              type: 'simple-line',
              color: 'pink',
              width: 2,
            },
            classBreakInfos: [
              {
                minValue: 0,
                maxValue: 60 * 60 * 3 - 1,
                symbol: lessThanThreeHoursLineSymbol,
                label: '0 ~ 3 Hours',
              },
              {
                minValue: 60 * 60 * 3,
                maxValue: 60 * 60 * 6 - 1,
                symbol: lessThanSixHoursLineSymbol,
                label: '3 ~ 6 Hours',
              },
              {
                minValue: 60 * 60 * 6,
                maxValue: 60 * 60 * 24 - 1,
                symbol: lessThanOneDayLineSymbol,
                label: '6 ~ 24 Hours',
              },
              {
                minValue: 60 * 60 * 24,
                maxValue: 60 * 60 * 24 * 31 * 12,
                symbol: extraLineSymbol,
                label: 'Extra',
              },
            ],
          })

          // define "detection info" action
          const detectionInfoAction = {
            title: 'Detection Info',
            id: 'detection-info-action',
            className: 'esri-icon-link-external',
          }

          const recentLogFirePopupTemplate = {
            title: 'Detection Log',
            outFields: ['*'],
            content: [
              {
                type: 'custom',
                creator: (item) => {
                  const cameraName = item.graphic.attributes.camera_name
                  const longitude = item.graphic.attributes.longitude !== -1 ? item.graphic.attributes.longitude.toString() : '-'
                  const latitude = item.graphic.attributes.latitude !== -1 ? item.graphic.attributes.latitude.toString() : '-'
                  const direction = item.graphic.attributes.pan_direction !== -1 ? util.locaitonToDegree(item.graphic.attributes.pan_direction) : '-'
                  return `
                  <table class="esri-widget__table">
                    <tbody>
                      <tr>
                        <th class="esri-feature-fields__field-header">Detected Camera</th>
                        <td class="esri-feature-fields__field-data">${cameraName}</td>
                      </tr>
                      <tr>
                        <th class="esri-feature-fields__field-header">Lat</th>
                        <td class="esri-feature-fields__field-data">${latitude}</td>
                      </tr>
                      <tr>
                        <th class="esri-feature-fields__field-header">Long</th>
                        <td class="esri-feature-fields__field-data">${longitude}</td>
                      </tr>
                      <tr>
                        <th class="esri-feature-fields__field-header">Direction</th>
                        <td class="esri-feature-fields__field-data">${direction}</td>
                      </tr>
                    </tbody>
                  </table>`
                },
              },
            ],
            actions: [detectionInfoAction],
            overwriteActions: true,
            returnGeometry: true,
          }

          // add geoJSON point graphics layer
          const recentLogGeoJsonPointDataLayer = new GeoJSONLayer({
            url: urlPrependString + 'map/log/recent?type=point',
            renderer: recentLogGeoJsonPointDataRenderer,
            popupTemplate: recentLogFirePopupTemplate,
            refreshInterval: 0.1,
          })
          this.logListGeoJsonPointDataLayer = recentLogGeoJsonPointDataLayer

          map.add(this.logListGeoJsonPointDataLayer)

          // apply filter on create/render
          this.logListGeoJsonPointDataLayer.on('layerview-create', function (evt) {
            self.applyLogGraphicsFilter(self.cameraFilter)
          })
          this.logListGeoJsonPointDataLayer.on('refresh', function (evt) {
            self.applyLogGraphicsFilter(self.cameraFilter)
            if (self.currentLog) {
              self.sceneview.graphics.removeAll()
              // self.drawExclamationMarkIcon()
              self.drawCircleGraphic()
            }
          })

          // add geoJSON lines graphics layer
          const recentLogGeoJsonLineDataLayer = new GeoJSONLayer({
            url: urlPrependString + 'map/log/recent?type=line',
            renderer: recentLogGeoJsonLineDataRenderer,
            popupTemplate: recentLogFirePopupTemplate,
            refreshInterval: 0.1,
          })
          this.logListGeoJsonLineDataLayer = recentLogGeoJsonLineDataLayer
          map.add(this.logListGeoJsonLineDataLayer)

          // add geoJSON point graphics layer
          const customDateRangeRecentLogGeoJsonPointDataLayer = new GeoJSONLayer({
            url: urlPrependString + 'map/log/all',
            renderer: recentLogGeoJsonPointDataRenderer,
            popupTemplate: recentLogFirePopupTemplate,
            customParameters: {
              start_date: moment().subtract(72, 'hours').format(),
              end_date: moment().format(),
              type: 'point',
            },
          })
          this.customDateRangeLogListGeoJsonPointDataLayer = customDateRangeRecentLogGeoJsonPointDataLayer
          map.add(this.customDateRangeLogListGeoJsonPointDataLayer)

          // add geoJSON lines graphics layer
          const customDateRangeRecentLogGeoJsonLineDataLayer = new GeoJSONLayer({
            url: urlPrependString + 'map/log/all',
            renderer: recentLogGeoJsonLineDataRenderer,
            popupTemplate: recentLogFirePopupTemplate,
            customParameters: {
              start_date: moment().subtract(72, 'hours').format(),
              end_date: moment().format(),
              type: 'line',
            },
          })
          this.customDateRangeLogListGeoJsonLineDataLayer = customDateRangeRecentLogGeoJsonLineDataLayer
          map.add(this.customDateRangeLogListGeoJsonLineDataLayer)

          this.setGeoJsonDataVisibleOption()

          // apply filter on create/render
          this.logListGeoJsonLineDataLayer.on('layerview-create', function (evt) {
            self.applyLogGraphicsFilter(self.cameraFilter)
          })
          this.logListGeoJsonLineDataLayer.on('refresh', function (evt) {
            self.applyLogGraphicsFilter(self.cameraFilter)
          })

          // RECENT LOG LIST GEOJSON: END

          // add search bar
          const searchWidget = new Search({
            view,
            allPlaceholder: 'Find camera or place',
            includeDefaultSources: false,
            sources: [
              {
                layer: camerasDataGeoJsonLayer,
                searchFields: ['NAME'],
                displayField: 'NAME',
                exactMatch: false,
                outFields: ['NAME'],
                name: 'Cameras',
                placeholder: 'Camera Name',
              },
              {
                layer: pfirsGeoJsonDataLayer,
                searchFields: ['BurnName'],
                displayField: 'BurnName',
                exactMatch: false,
                outFields: ['BurnName'],
                name: 'Prescribed Burns',
                placeholder: 'Burn Name',
              },
              {
                name: 'Address / Location',
                placeholder: 'Address or Location',
                apiKey: Constants.ARCGIS_API_KEY,
                singleLineFieldName: 'SingleLine',
                url: 'https://geocode-api.arcgis.com/arcgis/rest/services/World/GeocodeServer',
              },
            ],
          })
          view.ui.add(searchWidget, 'top-left')

          // define graphics layer
          const graphicsLayer = new GraphicsLayer({
            elevationInfo: {
              mode: 'on-the-ground',
            },
          })
          // apply graphics layer to map
          map.add(graphicsLayer)

          view.when(() => {
            view.on('key-down', function (evt) {
              if (evt.key === 'Control') {
                self.sceneview.navigation.mouseWheelZoomEnabled = true
              }
            })

            view.on('layerview-create', async function () {
              const cameraDataView = await self.sceneview.whenLayerView(self.camerasDataGeoJsonLayer)
              const organizationIdsList = self.organizationIds
              cameraDataView.filter = { where: `ID in (${organizationIdsList})` }
            })

            view.on('key-up', function (evt) {
              if (evt.key === 'Control') {
                self.sceneview.navigation.mouseWheelZoomEnabled = false
              }
            })

            view.on('pointer-move', function (evt) {
              // console.info('pointer', evt)
              const screenPoint = {
                x: evt.x,
                y: evt.y,
              }
              view.hitTest(screenPoint).then((response) => {
                changeMouseCursor(response)
              })
            })

            // change cursor to "pointer" when hovering over an icon
            function changeMouseCursor(res) {
              const hitPointGraphicsList = res.results.filter((item) => item.graphic.geometry.type === 'point')
              if (hitPointGraphicsList.length > 0) {
                document.body.style.cursor = 'pointer'
              } else {
                document.body.style.cursor = 'default'
              }
            }

            view.popup.viewModel.on('trigger-action', (event) => {
              const attributes = view.popup.viewModel.selectedFeature.attributes
              // this.$log.debug('on click detection info', view.popup.viewModel.selectedFeature)
              if (event.action.id === 'go-to-action' && attributes.public_url && attributes.public_url !== '') {
                window.open(attributes.public_url)
              }
              if (event.action.id === 'detection-info-action') {
                const matchedLog = this.filteredRecentLogList.filter((log) => log.log_id === attributes.log_id)[0]
                this.$store.dispatch('setMapViewSelectedLogAction', matchedLog)
              }
            })
          })
        }
      )
    },
    onEventWidgetButton(eventType, elemId) {
      const elemBtnDom = document.getElementById(elemId)
      const classList = elemBtnDom.className
      const isActive = classList.includes('active')
      switch (eventType) {
        case 'mouseover':
          if (elemId === 'directionsButton') {
            if (!isActive && !this.directionsButtonActive) this.directionsButtonActive = true
          }
          break
        case 'mouseleave':
          if (elemId === 'directionsButton') {
            if (!isActive && this.directionsButtonActive) this.directionsButtonActive = false
          }
          break
        default:
          break
      }
    },
    drawCircleGraphic() {
      loadModules(['esri/Graphic', 'esri/geometry/Circle'], {
        css: true,
      }).then(async ([Graphic, Circle]) => {
        // draw border circle graphic
        const NOW_MOMENT = moment()

        const CREATED_AT = moment(this.currentLog.created_at).format()
        const MINUTES_SINCE_FIRE_DETECTED = NOW_MOMENT.diff(CREATED_AT, 'minutes')

        let BORDER_COLOR = [249, 66, 58, 0.5]
        if (MINUTES_SINCE_FIRE_DETECTED > 24 * 60) {
          // debugColorString = 'gray >24 hours'
          BORDER_COLOR = [109, 109, 109, 0.5]
        } else if (MINUTES_SINCE_FIRE_DETECTED > 6 * 60) {
          // debugColorString = 'blue 6~24 hours'
          BORDER_COLOR = [56, 76, 255, 0.5]
        } else if (MINUTES_SINCE_FIRE_DETECTED > 3 * 60) {
          // debugColorString = 'yellow 3~6 hours'
          BORDER_COLOR = [255, 184, 0, 0.5]
        }

        const circleGeometry = new Circle({
          center: [this.currentLog?.longitude, this.currentLog?.latitude],
          geodesic: true,
          numberOfPoints: 100,
          radius: 1.2,
          radiusUnit: 'miles',
        })
        const fillSymbol = {
          type: 'simple-fill',
          color: BORDER_COLOR,
          outline: {
            width: 1,
            color: BORDER_COLOR,
          },
        }
        const currentFireAreaGraphic = new Graphic({
          geometry: circleGeometry,
          symbol: fillSymbol,
        })
        this.sceneview.graphics.add(currentFireAreaGraphic)
      })
    },
    applyCameraFilter(filter) {
      loadModules([], {
        css: true,
      }).then(async () => {
        // add filtering logic for cameras graphics
        const idListAsString = this.recentLogListIDs.join(',')
        const organizationIdsList = this.organizationIds
        this.cameraFilterIdList = idListAsString
        const view = await this.sceneview.whenLayerView(this.camerasDataGeoJsonLayer)
        if (['alert-only', 'none'].includes(filter)) {
          view.filter = { where: `ID in (${idListAsString})` }
        } else {
          view.filter = { where: `ID in (${organizationIdsList})` }
        }
      })

      this.setGeoJsonDataVisibleOption()
    },
    applyPfirsFilter(filter) {
      loadModules([], {
        css: true,
      }).then(async () => {
        const view = await this.sceneview.whenLayerView(this.pfirsDataFeatureLayer)
        view.visible = filter !== 'none'
      })
    },
    applyLogGraphicsFilter(cameraFilter) {
      loadModules([], {
        css: true,
      }).then(async () => {
        let idListAsString = ''
        if (['alert-only', 'none'].includes(cameraFilter)) {
          idListAsString = this.recentLogListIDs.join(',')
        } else {
          idListAsString = this.organizationIds
        }
        const filteredLogIDListString = this.recentLogListLogIDs.join(',')
        // this.$log.debug('applyLogGraphicsFilter', filteredLogIDListString)
        if (!this.isCustomDateFilter) {
          // recent log list data layers
          try {
            const pointsDataLayerView = await this.sceneview.whenLayerView(this.logListGeoJsonPointDataLayer)
            pointsDataLayerView.filter = { where: `LOG_ID in (${filteredLogIDListString}) AND CAMERA_ID in (${idListAsString})` }
            const linesDataLayerView = await this.sceneview.whenLayerView(this.logListGeoJsonLineDataLayer)
            linesDataLayerView.filter =
              cameraFilter === 'none'
                ? { where: `LOG_ID in ()` }
                : { where: `LOG_ID in (${filteredLogIDListString}) AND CAMERA_ID in (${idListAsString})` }
          } catch (e) {
            console.log(e)
          }
        } else {
          // custom date range log list data layers
          try {
            const customPointsDataLayerView = await this.sceneview.whenLayerView(this.customDateRangeLogListGeoJsonPointDataLayer)
            customPointsDataLayerView.filter = { where: `LOG_ID in (${filteredLogIDListString}) AND CAMERA_ID in (${idListAsString})` }
            const customLinesDataLayerView = await this.sceneview.whenLayerView(this.customDateRangeLogListGeoJsonLineDataLayer)
            customLinesDataLayerView.filter =
              cameraFilter === 'none'
                ? { where: `LOG_ID in ()` }
                : { where: `LOG_ID in (${filteredLogIDListString}) AND CAMERA_ID in (${idListAsString})` }
          } catch (e) {
            console.log(e)
          }
        }
      })
    },
    setGeoJsonDataVisibleOption() {
      this.customDateRangeLogListGeoJsonPointDataLayer.visible = this.isCustomDateFilter
      this.customDateRangeLogListGeoJsonLineDataLayer.visible = this.isCustomDateFilter && this.cameraFilter !== 'none'
      this.logListGeoJsonPointDataLayer.visible = !this.isCustomDateFilter
      this.logListGeoJsonLineDataLayer.visible = !this.isCustomDateFilter && this.cameraFilter !== 'none'
      this.camerasDataGeoJsonLayer.visible = this.cameraFilter !== 'none'
    },
    handleCustomDateFilter() {
      this.logListGeoJsonPointDataLayer.visible = !this.isCustomDateFilter
      this.logListGeoJsonLineDataLayer.visible = !this.isCustomDateFilter
      // update custom date api to get new data for geojsonlayer
      const startDate = this.mapViewFilter.detectionWithin.dateObj?.startDate?.format() || moment().subtract(72, 'hours').startOf('day').format()
      const endDate = this.mapViewFilter.detectionWithin.dateObj?.endDate?.format() || moment().format()

      this.customDateRangeLogListGeoJsonPointDataLayer.customParameters.start_date = startDate
      this.customDateRangeLogListGeoJsonPointDataLayer.customParameters.end_date = endDate
      this.customDateRangeLogListGeoJsonPointDataLayer.refresh()

      this.customDateRangeLogListGeoJsonLineDataLayer.customParameters.start_date = startDate
      this.customDateRangeLogListGeoJsonLineDataLayer.customParameters.end_date = endDate
      this.customDateRangeLogListGeoJsonLineDataLayer.refresh()

      this.customDateRangeLogListGeoJsonPointDataLayer.visible = this.isCustomDateFilter
      this.customDateRangeLogListGeoJsonLineDataLayer.visible = this.isCustomDateFilter
    },
    centerMapToCurrentLog() {
      const camCoordinates = this.camMap[this.currentLog.camera_id].coordinate.split(' ')
      let longitude = !!this.currentLog.longitude && this.currentLog.longitude !== -1 ? this.currentLog.longitude : camCoordinates[1]
      let latitude = !!this.currentLog.latitude && this.currentLog.latitude !== -1 ? this.currentLog.latitude : camCoordinates[0]
      // calculate coordinates of midpoint of line graphic
      if (
        !!this.currentLog.longitude &&
        this.currentLog.longitude === -1 &&
        !!this.currentLog.latitude &&
        this.currentLog.latitude === -1 &&
        this.currentLog.pan_direction !== -1
      ) {
        const midpointCoordinates = this.computePosition({ lat: 0, lng: 0 }, 5000, this.currentLog.pan_direction)
        longitude = Number(camCoordinates[1]) + midpointCoordinates.lng()
        latitude = Number(camCoordinates[0]) + midpointCoordinates.lat()
      }

      this.sceneview
        .goTo(
          {
            center: [Number(longitude), Number(latitude)],
            zoom: 12,
          },
          {
            speedFactor: 3,
            easing: 'linear',
          }
        )
        .catch((e) => {
          this.$log.debug('catch ArcGIS goTo() error')
        })
    },
    centerMapToFirstLog() {
      const firstDetectionLogs = this.recentLogList.filter((log) => !util.isDuplicateLog(log))
      if (firstDetectionLogs.length) {
        const mostRecentFirstDetectionLog = firstDetectionLogs[0]
        const matchedCameraList = this.camList.filter((cam) => cam.id === mostRecentFirstDetectionLog.camera_id)
        if (matchedCameraList.length) {
          let longitude =
            mostRecentFirstDetectionLog.longitude !== -1 ? mostRecentFirstDetectionLog.longitude : matchedCameraList[0].coordinate.split(' ')[1]
          let latitude =
            mostRecentFirstDetectionLog.latitude !== -1 ? mostRecentFirstDetectionLog.latitude : matchedCameraList[0].coordinate.split(' ')[0]
          // calculate coordinates of midpoint of line graphic
          if (
            !!mostRecentFirstDetectionLog.longitude &&
            mostRecentFirstDetectionLog.longitude === -1 &&
            !!mostRecentFirstDetectionLog.latitude &&
            mostRecentFirstDetectionLog.latitude === -1 &&
            mostRecentFirstDetectionLog.pan_direction !== -1
          ) {
            const midpointCoordinates = this.computePosition({ lat: 0, lng: 0 }, 5000, mostRecentFirstDetectionLog.pan_direction)
            longitude = Number(matchedCameraList[0].coordinate.split(' ')[1]) + midpointCoordinates.lng()
            latitude = Number(matchedCameraList[0].coordinate.split(' ')[0]) + midpointCoordinates.lat()
          }

          this.sceneview
            .goTo(
              {
                center: [Number(longitude), Number(latitude)],
                zoom: 12,
              },
              {
                speedFactor: 1000,
                easing: 'linear',
              }
            )
            .then(() => {
              this.isCenteredToFirstLog = true
              // this.updateMapNoLogSelectedGraphics()
            })
            .catch((e) => {
              this.$log.debug('catch ArcGIS goTo() error')
            })
        }
      }
    },
    computePosition(latLng, distance, heading) {
      return google.maps.geometry.spherical.computeOffset(latLng, distance, heading)
    },
    detectionTypeFilterMethod(log, filter) {
      if (!!filter.firstDetection) {
        return !util.isDuplicateLog(log)
      }
      return true
    },
    detectionWithinFilterMethod(log, filter) {
      if (filter.dateObj === null) return true
      const createdTime = moment(log.created_at)
      const NOW_MOMENT = moment().format()
      if (!filter.isCustom) {
        const START_MOMENT = moment().subtract(filter.selectedHoursFilter, 'hours')
        return createdTime.isBetween(START_MOMENT, NOW_MOMENT)
      }
      return createdTime.isBetween(filter.dateObj.startDate.startOf('day'), filter.dateObj.endDate.endOf('day'))
    },
    responseStatusFilterMethod(log, filter) {
      const status = this.getStatus(log)
      if ((status === 'Alerted' && filter.alerted) || (status === 'Validated' && filter.validated) || (status === 'Responded' && filter.responded))
        return true
      else return false
    },
    getStatus(item) {
      if (!item) {
        return ''
      }
      let result = 'Alerted'
      if (item.response_status.is_validated) {
        result = 'Validated'
      }
      if (item.response_status.is_responded) {
        result = 'Responded'
      }
      return result
    },
    eventTypeFilterMethod(log, filter) {
      const eventType = log.event_type_id
      if (
        (eventType === 1 && filter.unselected) ||
        (eventType === 2 && filter.fire) ||
        (eventType === 3 && filter.nonFire) ||
        (eventType === 4 && filter.unknown) ||
        (eventType === 5 && filter.plannedBurn)
      ) {
        return true
      }
      return false
    },
  },
}
</script>

<style lang="scss" scoped>
// #elevationDiv {
//   position: absolute;
//   bottom: 30px;
//   left: 12px;
//   padding: 12px;
//   background-color: rgba(0, 0, 0, 0.5);
//   color: white;
// }

#topbar {
  background: #fff;
  // position: absolute;
  // right: 15px;
  // top: 70px;
  display: flex;
  flex-direction: column;
}

.directions-button,
.action-button {
  font-size: 16px;
  background-color: transparent;
  border: 1px solid #d3d3d3;
  color: #6e6e6e;
  height: 32px;
  width: 32px;
  text-align: center;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
}

.directions-button:hover,
.action-button:hover,
.action-button:focus {
  background: #0079c1;
  color: #e4e4e4;
}

.active {
  background: #0079c1;
  color: #e4e4e4;
}
</style>
