var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"1005","persistent":"","no-click-animation":""},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.hide.apply(null, arguments)}},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',{class:("live-view-dialog-card " + _vm.themeName),attrs:{"data-testid":"DetectionClip_Modal"}},[_c('div',{class:("live-view-dialog-title " + _vm.themeName)},[(_vm.providerLogoUrl)?_c('div',{staticClass:"detection-clip-camera-provider"},[_vm._v(" Cameras by "),_c('img',{staticStyle:{"margin-left":"7px"},attrs:{"src":_vm.providerLogoUrl,"width":"113px"}})]):_vm._e(),_vm._v(" Detection Clip ")]),_c('div',{staticClass:"live-view-dialog-close",attrs:{"data-testid":"Close_Button"},on:{"click":_vm.hide}},[(_vm.$vuetify.theme.dark)?_c('img',{attrs:{"src":require("@/assets/Exit_Icon.svg"),"width":"16px"}}):_c('img',{attrs:{"src":require("@/assets/Exit_Icon_Black.svg"),"width":"15px"}})]),_c('div',{staticStyle:{"height":"613px","width":"1005px","padding":"0 40px 20px 40px"}},[(_vm.imagePlayerLoad)?_c('ImagePlayer',{attrs:{"currentLog":_vm.mapViewSelectedLog},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var currentIndex = ref.currentIndex;
var moveFrame = ref.moveFrame;
var playerState = ref.playerState;
var imageUrlList = ref.imageUrlList;
var detectedImageIndex = ref.detectedImageIndex;
var hasAfterImages = ref.hasAfterImages;
var play = ref.play;
var pause = ref.pause;
var currentLogId = ref.currentLogId;
return [_c('FramePlayerBar',{attrs:{"currentLogId":currentLogId,"currentIndex":currentIndex,"playerState":playerState,"imageUrlList":imageUrlList,"detectedImageIndex":detectedImageIndex,"hasAfterImages":hasAfterImages},on:{"moveFrame":moveFrame,"play":play,"pause":pause}})]}}],null,false,705789397)}):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }