<template>
  <div class="map-view">
    <audio ref="audio" src="@/assets/alert.wav" preload loop>Your browser does not support the audio element.</audio>
    <section :class="themeName">
      <SelectDrawer @stopAlert="stopAlert" :mapviewAlertOn="mapviewAlertOn" />
      <MainInterface />
    </section>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import RotuerMixin from '@/mixin/router.mixin'
import SelfInfoMixin from '@/mixin/selfinfo.mixin'
import Theme from '@/mixin/theme.mixin'
import Util from '@/util'
import Vue from 'vue'
import { POSITION } from 'vue-toastification'

import SelectDrawer from '@desktop/adminOrViewer/mapView/MapSelectDrawer'
import MainInterface from '@desktop/adminOrViewer/mapView/MapMainInterface'

import Constants from '@/constants'

export default {
  name: 'MapView',
  mixins: [RotuerMixin, SelfInfoMixin, Theme],
  components: {
    SelectDrawer,
    MainInterface,
  },
  data() {
    return {
      refreshInterval: null,
      alertAudioTimer: null,
      alertBorderTimer: null,
      stopAlertWhileBrowsing: false,
      mapviewAlertOn: false,
      // recentLogListInMapview query parameters
      includeChildLogs: true,
      includeNotificationInformation: true,
      includeDuplicatesByOrganization: true,
      includeResponseStatusInformation: true,
      // alarm-related variables
      initialized: false,
    }
  },
  computed: {
    ...mapState([
      'camMap',
      'camList',
      'recentLogList',
      'recentLogListv2',
      'eventTypeList',
      'user',
      'currentLog',
      'currentCam',
      'isMapviewAlertOff',
      'mapViewSelectedLog',
      'mapViewFilter',
    ]),
    recentLogs() {
      let logs = this.recentLogList.slice(0, 6).filter((log) => Util.checkRecent72(log.created_at, log.offset))
      if (logs.length) {
        return logs
      } else if (this.recentLogList.length) {
        return [this.recentLogList[0]]
      } else {
        return []
      }
    },
    isAlertOff() {
      return this.isMapviewAlertOff
    },
  },
  watch: {
    mapViewFilter(newFilter, oldFilter) {
      // if custom date range selected
      if (!oldFilter.detectionWithin.isCustom && newFilter.detectionWithin.isCustom) {
        // disable alert if on
        if (this.mapviewAlertOn) this.stopAlert()
        // stop polling
        clearTimeout(this.refreshInterval)
      } else if (oldFilter.detectionWithin.isCustom && !newFilter.detectionWithin.isCustom) {
        // start polling
        this.startRecentLogListPolling()
      }
    },
    recentLogList(newList, oldList) {
      if (this.initialized && !this.mapViewFilter.detectionWithin.isCustom) {
        // create log ID map of oldList
        let idMap = {}
        oldList.forEach((log) => {
          idMap[log.log_id] = log
        })
        // iterate through each of the logs in newList, check against log ID map
        for (let i = 0; i < newList.length; i += 1) {
          // check if true new log comes in
          if (!idMap[newList[i].log_id] && !Util.isDuplicateLog(newList[i]) && !this.isAlertOff) {
            this.$store.dispatch('setMapViewSelectedLogAction', newList[i])
            this.startAlert()
            break
          }
        }
      }
    },
  },
  async created() {
    await this.getCamList({ justCamList: true })
    await this.getRecentLogListv2({
      tabIndex: 1,
      inNoti: this.includeNotificationInformation,
      inRepOrg: this.includeDuplicatesByOrganization,
      inRespStat: this.includeResponseStatusInformation,
      inChild: this.includeChildLogs,
    })
    await this.initialize()
  },
  destroyed() {
    clearTimeout(this.refreshInterval)
    clearTimeout(this.alertAudioTimer)
    clearTimeout(this.alertBorderTimer)
    this.$store.dispatch('setShowMapViewResponseStatusPopoverAction', false)
    this.$toast.clear()
  },
  methods: {
    ...mapActions(['setCurrentLog', 'getCamList', 'getRecentLogListInMapview', 'getRecentLogListv2', 'getLogList']),
    initialize() {
      const updateToastDefaultOptions = {
        hideProgressBar: true,
        icon: false,
        position: POSITION.TOP_RIGHT,
        timeout: 10000,
      }
      Vue.$toast.updateDefaults(updateToastDefaultOptions)
      this.startRecentLogListPolling()
    },
    startRecentLogListPolling() {
      clearTimeout(this.refreshInterval)
      this.refreshInterval = setTimeout(async () => {
        // const firstLog = this.recentLogListv2 && this.recentLogListv2.length && this.recentLogListv2[0]
        await this.getRecentLogListInMapview({
          includeChildLogs: this.includeChildLogs,
          includeNotificationInformation: this.includeNotificationInformation,
          includeDuplicatesByOrganization: this.includeDuplicatesByOrganization,
          includeResponseStatusInformation: this.includeResponseStatusInformation,
          tabIndex: 1,
          themeName: this.themeName,
        })
        if (!this.initialized) this.initialized = true
        // const updatedFirstLog = this.recentLogListv2 && this.recentLogListv2.length && this.recentLogListv2[0]
        // // refresh mapview selected log

        // if (this.mapViewSelectedLog && this.mapViewSelectedLog?.log_id) {
        //   const updatedLog = this.recentLogs.filter((log) => log.log_id === this.mapViewSelectedLog.log_id)
        //   if (updatedLog.length) {
        //     this.$store.dispatch('setMapViewSelectedLogAction', updatedLog[0])
        //   }
        // }
        // if (
        //   firstLog.camera_id &&
        //   updatedFirstLog.camera_id &&
        //   firstLog.camera_id !== updatedFirstLog.camera_id &&
        //   !this.isAlertOff &&
        //   !updatedFirstLog.is_checked_organization
        // ) {
        //   // select most recent log and trigger alarm
        //   this.$store.dispatch('setMapViewSelectedLogAction', updatedFirstLog)

        //   const updatedLogPauseEndTime = this.camMap[updatedFirstLog.camera_id].noti_pause_end

        //   if (!updatedLogPauseEndTime || moment().isAfter(updatedLogPauseEndTime)) this.startAlert(updatedFirstLog)
        // }
        this.startRecentLogListPolling()
      }, Constants.INTERVAL_SITE_LOG)
      // refresh rate: 5 seconds
    },

    startAlert() {
      this.mapviewAlertOn = true
      this.$refs.audio.play()

      if (this.alertAudioTimer !== null) clearTimeout(this.alertAudioTimer)
      this.alertAudioTimer = setTimeout(() => {
        this.$refs.audio.pause()
      }, 12000)

      if (this.alertBorderTimer !== null) clearTimeout(this.alertBorderTimer)
      this.alertBorderTimer = setTimeout(() => {
        this.mapviewAlertOn = false
      }, 60000)
    },
    stopAlert() {
      this.$refs.audio.pause()
      this.mapviewAlertOn = false
    },
  },
}
</script>

<style lang="scss" scoped>
.map-view {
  display: flex;
  height: calc(100% - 80px);
}

section {
  flex-grow: 1;
  display: flex;
  height: 0;
  min-height: 100%;
  overflow: auto;
  background-color: var(--f-text-white);

  &.dark {
    background-color: var(--f-bg-low);
  }
}
</style>
