<template>
  <div :class="`map-select-drawer ${themeName}`" data-testid="MapView_Recent_Area">
    <div v-if="!selectedLogInfo" :class="`recent-log-list-container ${themeName}`">
      <v-progress-circular v-if="!isLogListLoaded" style="margin: auto" :size="50" color="#FF5B54" indeterminate></v-progress-circular>
      <div :class="`no-data ${themeName}`" v-else-if="isLogListLoaded && !filteredRecentLogList.length">
        <span :class="`title ${themeName}`">No data available</span>
        <span class="content">Try changing or removing</span>
        <span class="content">some of your filters</span>
      </div>
      <div v-else>
        <div :class="`recent-log-list-title ${themeName}`">{{ mapViewDrawerTitle }}</div>
        <div
          v-for="item in filteredRecentLogList"
          :key="item.log_id"
          :class="`recent-log-list-item-list ${item.log_id === expandedAccordionLogId ? 'selected' : ''}`"
        >
          <div :key="item" :class="`recent-log-list-item ${themeName}`" @click="onClickLogItem(item)">
            <div class="recent-log-list-item-info">
              <div class="recent-log-list-item-info-top">
                <div v-if="item.event_type_id === eventTypeList[2].id" class="recent-log-list-item-color-circle fire"></div>
                <div v-else-if="item.event_type_id === eventTypeList[3].id" class="recent-log-list-item-color-circle non-fire"></div>
                <div v-else-if="item.event_type_id === eventTypeList[4].id" class="recent-log-list-item-color-circle unknown"></div>
                <div v-else-if="item.event_type_id === eventTypeList[5].id" class="recent-log-list-item-color-circle planned"></div>
                <div v-else :class="`recent-log-list-item-color-circle default ${themeName}`"></div>
                <div :class="`recent-log-list-item-camera-name ${themeName}`">{{ item.camera_name }}</div>
              </div>
              <div :class="`recent-log-list-item-info-bottom ${themeName}`">
                {{ logTimestampFormat(item) }}
                <img v-if="showTimeoutIcon(item)" :src="require('@/assets/Timeout_Icon_Pink.svg')" class="timeout-icon" />
              </div>
            </div>
            <div :class="`recent-log-list-item-status ${getStatusClass(item)}`">{{ getStatus(item) }}</div>
            <div class="recent-log-list-item-accordion-expand-icon-area" v-if="!onlyFirstDetection && item.has_repetitive">
              <!-- is loading repetitive logs -->
              <v-progress-circular
                v-if="item.log_id === expandedAccordionLogId && isLogAccordionLoading"
                :color="themeName === 'dark' ? '#FFFFFF' : '#00000061'"
                :size="16"
                :width="2"
                indeterminate
              />
              <!-- expanded  -->
              <img
                v-else-if="!isLogAccordionLoading && item.log_id === expandedAccordionLogId && themeName === 'dark'"
                :src="require('@/assets/Chevron-Up_16px_Icon-Light.svg')"
                height="16"
                width="16"
                @click.stop="onClickLogAccordion(item)"
              />
              <img
                v-else-if="!isLogAccordionLoading && item.log_id === expandedAccordionLogId && themeName !== 'dark'"
                :src="require('@/assets/Chevron-Up_16px_Icon-Dark.svg')"
                height="16"
                width="16"
                @click.stop="onClickLogAccordion(item)"
              />
              <!-- folded -->
              <img
                v-else-if="item.log_id !== expandedAccordionLogId && themeName === 'dark'"
                :src="require('@/assets/Chevron-Down_16px_Icon-Light.svg')"
                height="16"
                width="16"
                @click.stop="onClickLogAccordion(item)"
              />
              <img
                v-else-if="item.log_id !== expandedAccordionLogId && themeName !== 'dark'"
                :src="require('@/assets/Chevron-Down_16px_Icon-Dark.svg')"
                height="16"
                width="16"
                @click.stop="onClickLogAccordion(item)"
              />
            </div>
            <div v-else style="width: 41px" />
          </div>
          <!-- duplicate alarm(s) list -->
          <div
            v-if="!onlyFirstDetection && item.log_id === expandedAccordionLogId && isLogAccordionLoading"
            :class="`recent-log-list-item-duplicate-alarms-list-loading-container ${themeName}`"
          >
            <v-progress-circular :color="themeName === 'dark' ? '#FFFFFF' : '#00000061'" :size="32" :width="3" indeterminate />
          </div>
          <div
            v-else-if="!onlyFirstDetection && item.log_id === expandedAccordionLogId && !isLogAccordionLoading"
            :class="`recent-log-list-item-duplicate-alarms-list-container ${themeName}`"
          >
            <div v-if="!filteredLogChildren.length" :class="`empty-log-list-children-container ${themeName}`">No logs in this date range</div>
            <div v-for="repLog in filteredLogChildren" :key="repLog.log_id">
              <div :class="`recent-log-list-item duplicate-alarm-item ${themeName}`" style="width: 324px" @click="onClickLogChild(repLog)">
                <div class="recent-log-list-item-info" style="width: 209px">
                  <div class="recent-log-list-item-info-top">
                    <div style="width: 17px"></div>
                    <div :class="`recent-log-list-item-camera-name ${themeName}`">{{ repLog.camera_name }}</div>
                  </div>
                  <div :class="`recent-log-list-item-info-bottom ${themeName}`">{{ logTimestampFormat(repLog) }}</div>
                </div>
                <div :class="`recent-log-list-item-status ${getStatusClass(repLog)}`">Repetitive</div>
              </div>
            </div>
          </div>
        </div>
        <!-- </template>
        </v-virtual-scroll> -->
      </div>
    </div>

    <div
      v-if="selectedLogInfo"
      :class="`detection-info-container ${themeName} ${mapviewAlertOn ? 'alarm' : ''}`"
      data-testid="MapView_DetectionInfo_Area"
    >
      <!-- Selected Log Info title + basic info -->
      <div class="detection-info-top">
        <img
          v-if="themeName === 'dark'"
          :class="`detection-info-btn-close ${themeName}`"
          src="@/assets/Close_Line_12px_1_Icon_dark.svg"
          width="12"
          data-testid="Close_Button"
          @click="onClickDetectionInfoClose"
        />
        <img
          v-else
          :class="`detection-info-btn-close ${themeName}`"
          src="@/assets/Close_Line_12px_1_Icon.svg"
          width="12"
          @click="onClickDetectionInfoClose"
        />
        <div :class="`detection-info-title ${themeName}`">Detection Info</div>
        <div class="detection-item-info-container">
          <div class="recent-log-list-item-info">
            <div class="recent-log-list-item-info-top">
              <div v-if="selectedLogInfo.event_type_id === eventTypeList[2].id" class="recent-log-list-item-color-circle fire"></div>
              <div v-else-if="selectedLogInfo.event_type_id === eventTypeList[3].id" class="recent-log-list-item-color-circle non-fire"></div>
              <div v-else-if="selectedLogInfo.event_type_id === eventTypeList[4].id" class="recent-log-list-item-color-circle unknown"></div>
              <div v-else-if="selectedLogInfo.event_type_id === eventTypeList[5].id" class="recent-log-list-item-color-circle planned"></div>
              <div v-else :class="`recent-log-list-item-color-circle default ${themeName}`"></div>
              <div :class="`recent-log-list-item-camera-name ${themeName}`">{{ selectedLogInfo.camera_name }}</div>
            </div>
            <div :class="`recent-log-list-item-info-bottom ${themeName}`">
              <div style="margin-right: 8px">{{ logTimestampFormat(selectedLogInfo) }}</div>
              <img v-if="showTimeoutIcon(selectedLogInfo)" :src="require('@/assets/Timeout_Icon_Pink.svg')" class="timeout-icon" />
            </div>
          </div>
          <div
            :class="`recent-log-list-item-status ${getStatusClass(selectedLogInfo)}`"
            @click="onClickItemStatusBtn()"
            :key="selectedLogInfo.root_log_id"
          >
            {{ getStatus(selectedLogInfo) }}
          </div>
        </div>
      </div>
      <!-- LIVE VIEW, DETECTION, GO TO, SHARE Options -->
      <div class="detection-info-middle">
        <div
          :class="`detection-info-option ${themeName}`"
          style="width: 87px"
          @click="onClickOption('live-view')"
          data-testid="MapView_LiveView_Button"
        >
          <img v-if="themeName === 'dark'" src="@/assets/Live_Line_2_20px_Icon.svg" width="20" />
          <img v-else src="@/assets/Live_Line_2_20px_Icon_black.svg" width="20" />
          <div :class="`detection-info-option-text ${themeName}`">Live View</div>
        </div>
        <div :class="`detection-info-option-spacer-line ${themeName}`"></div>
        <div
          :class="`detection-info-option ${themeName}`"
          style="width: 88px"
          @click="onClickOption('detection')"
          data-testid="MapView_DetectionClip_Button"
        >
          <img v-if="themeName === 'dark'" src="@/assets/Detection_Line_2_20px_Icon.svg" width="20" />
          <img v-else src="@/assets/Detection_Line_2_20px_Icon_black.svg" width="20" />
          <div :class="`detection-info-option-text ${themeName}`">Detection</div>
        </div>
        <div :class="`detection-info-option-spacer-line ${themeName}`"></div>
        <div :class="`detection-info-option ${themeName}`" style="width: 87px" @click="onClickOption('go-to')" data-testid="MapView_Goto_Button">
          <img v-if="themeName === 'dark'" src="@/assets/Goto_Line_20px_Icon.svg" width="20" />
          <img v-else src="@/assets/Goto_Line_20px_Icon_black.svg" width="20" />
          <div :class="`detection-info-option-text ${themeName}`">Go to</div>
        </div>
        <div :class="`detection-info-option-spacer-line ${themeName}`"></div>
        <div :class="`detection-info-option ${themeName}`" style="width: 88px" @click="onClickOption('share')" data-testid="MapView_ShareLink_Button">
          <img v-if="themeName === 'dark'" src="@/assets/Link_Line_2_20px_Icon.svg" width="20" />
          <img v-else src="@/assets/Link_Line_2_20px_Icon_black.svg" width="20" />
          <div :class="`detection-info-option-text ${themeName}`">Share</div>
        </div>
      </div>
      <!-- Selected Log detailed info -->
      <div class="detection-info-bottom">
        <div :class="`detection-detailed-info-top ${themeName}`">
          <div class="detection-detailed-info-row">
            <div :class="`detection-detailed-info-label-text ${themeName}`">Event Type</div>
            <div :class="`detection-detailed-info-value-text ${themeName}`">
              <div v-if="selectedLogInfo.event_type_id === eventTypeList[2].id" class="recent-log-list-item-color-circle fire"></div>
              <div v-else-if="selectedLogInfo.event_type_id === eventTypeList[3].id" class="recent-log-list-item-color-circle non-fire"></div>
              <div v-else-if="selectedLogInfo.event_type_id === eventTypeList[4].id" class="recent-log-list-item-color-circle unknown"></div>
              <div v-else-if="selectedLogInfo.event_type_id === eventTypeList[5].id" class="recent-log-list-item-color-circle planned"></div>
              <!-- <div v-else :class="`recent-log-list-item-color-circle default ${themeName}`"></div> -->
              <div style="margin-right: 11px">{{ eventTypeValue }}</div>
              <div
                :class="`detection-detailed-info-event-type-btn-edit ${isEventTypeEditDisabled ? '' : 'event-type-editable'}`"
                @click="onClickEventTypeEdit()"
              >
                <img v-if="!isEventTypeEditDisabled" src="@/assets/Edit_Line_1_12px_Icon_red.svg" width="12" />
                <img v-else src="@/assets/Edit_Icon_Grey.svg" width="12" />
                <div :class="{ 'detection-detailed-info-event-type-btn-edit-text': true, viewer: isEventTypeEditDisabled }">Edit</div>
              </div>
            </div>
          </div>
          <div class="detection-detailed-info-row">
            <div :class="`detection-detailed-info-label-text ${themeName}`">Event ID</div>
            <div :class="`detection-detailed-info-value-text ${themeName}`" data-testid="MapView_EventID_Label">{{ eventIdValue }}</div>
          </div>
          <div class="detection-detailed-info-row">
            <div :class="`detection-detailed-info-label-text ${themeName}`">Detected Time</div>
            <div :class="`detection-detailed-info-value-text ${themeName}`" data-testid="MapView_DetectedTime_Label">{{ detectedTimeValue }}</div>
          </div>
          <div class="detection-detailed-info-row" style="max-height: 20px">
            <div :class="`detection-detailed-info-label-text ${themeName}`">Fire Location</div>
            <div
              data-testid="MapView_FireLocation_Label"
              v-if="fireLocationValue !== 'Loading...'"
              :class="`detection-detailed-info-value-text ${fireLocationValue === '-' ? 'text-no-drag' : ''} ${themeName}`"
            >
              {{ fireLocationValue }}
              <img
                v-if="fireLocationValue !== '-'"
                src="@/assets/Mobile_Copy_Button_Red.svg"
                height="14"
                style="margin-left: 8px; cursor: pointer"
                @click="onClickCopy(fireLocationValue)"
              />
              <img v-else-if="themeName === 'dark'" src="@/assets/Mobile_Copy_Button_Disabled-Dark.svg" height="14" style="margin-left: 8px" />
              <img v-else src="@/assets/Mobile_Copy_Button_Disabled-Light.svg" height="14" style="margin-left: 8px" />
              <v-tooltip v-if="fireLocationIsTriangulated" top :content-class="`triangulated-tooltip ${themeName}`">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    v-if="themeName === 'dark'"
                    src="@/assets/Info_Line_16px_Icon_Dark.svg"
                    v-bind="attrs"
                    v-on="on"
                    class="triangulated-tooltip-icon"
                    height="14"
                    width="14"
                  />
                  <img
                    v-else
                    src="@/assets/Info_Line_16px_Icon_Light.svg"
                    v-bind="attrs"
                    v-on="on"
                    class="triangulated-tooltip-icon"
                    height="14"
                    width="14"
                  />
                </template>
                <span :class="`triangulated-tooltip-text ${themeName}`">Triangulated</span>
              </v-tooltip>
            </div>
            <img v-else-if="themeName === 'dark'" src="@/assets/Message-1s-200px.svg" height="40" />
            <img v-else src="@/assets/Message-1s-200px-light.svg" height="40" />
          </div>
          <div class="detection-detailed-info-row" style="max-height: 20px">
            <div :class="`detection-detailed-info-label-text ${themeName}`">Fire Direction</div>
            <div
              v-if="fireDirectionValue !== 'Loading...'"
              :class="`detection-detailed-info-value-text ${fireDirectionValue === '-' ? 'text-no-drag' : ''} ${themeName}`"
            >
              {{ fireDirectionValue }}
              <img
                v-if="fireDirectionValue !== '-'"
                src="@/assets/Mobile_Copy_Button_Red.svg"
                height="14"
                style="margin-left: 8px; cursor: pointer"
                @click="onClickCopy(fireDirectionValue)"
              />
              <img v-else-if="themeName === 'dark'" src="@/assets/Mobile_Copy_Button_Disabled-Dark.svg" height="14" style="margin-left: 8px" />
              <img v-else src="@/assets/Mobile_Copy_Button_Disabled-Light.svg" height="14" style="margin-left: 8px" />
            </div>
            <img v-else-if="themeName === 'dark'" src="@/assets/Message-1s-200px.svg" height="40" />
            <img v-else src="@/assets/Message-1s-200px-light.svg" height="40" />
          </div>
          <div class="detection-detailed-info-row" style="margin-bottom: 0px !important">
            <div :class="`detection-detailed-info-label-text ${themeName}`">Camera Location</div>
            <div :class="`detection-detailed-info-value-text ${themeName}`" data-testid="MapView_CameraLocation_Label">{{ cameraLocationValue }}</div>
          </div>
        </div>
        <div :class="`detection-detailed-info-bottom ${themeName}`">
          <div class="detection-detailed-info-row" v-for="(weatherItem, wIndex) in weatherInfo" :key="wIndex">
            <div :class="`detection-detailed-info-label-text ${themeName}`">{{ weatherItem.label }}</div>
            <div :class="`detection-detailed-info-value-text ${themeName}`" :data-testid="`${weatherItem.testId}`">
              {{ selectedLogInfo[weatherItem.key] || '-' }}
            </div>
          </div>
        </div>
      </div>
      <div v-if="mapviewAlertOn" class="detection-stop-alert">
        <SmallButton @onClick="stopAlert">Stop</SmallButton>
      </div>
    </div>
    <EditEventTypeDialog
      v-model="showEditEventTypeDialog"
      :data="selectedLogInfo"
      @input="showEditEventTypeDialog = false"
      @ok="onSaveEventType($event)"
    ></EditEventTypeDialog>
    <GotoDialog v-model="dialogGoto" :urls="goToLinks"></GotoDialog>
    <SnackBar
      v-model="clipboard"
      :color="clipboardColor"
      :message="clipboardMsg"
      :data-testid="`${clipboardMsg === 'Copied to clipboard.' ? 'Copy_Success_Toast' : ''}`"
    ></SnackBar>
    <LiveViewDialog v-model="showLiveViewDialog" :selectedLogInfo="selectedLogInfo" @close="showLiveViewDialog = false"></LiveViewDialog>
    <DetectionClipDialog
      v-if="showDetectionClipDialog"
      v-model="showDetectionClipDialog"
      @close="showDetectionClipDialog = false"
    ></DetectionClipDialog>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import Theme from '@/mixin/theme.mixin'
import moment from 'moment'
import Util from '@/util'
import EditEventTypeDialog from '@desktop/dialog/EditLabelDialogAdmin'
import GotoDialog from '@desktop/dialog/GotoDialogAdmin'
import SnackBar from '@common/SnackBarClipboard'
import LiveViewDialog from '@desktop/dialog/LiveViewDialog'
import DetectionClipDialog from '@desktop/dialog/DetectionClipDialog'
import SmallButton from '@components/item/button/SmallButton'

export default {
  mixins: [Theme],
  components: { EditEventTypeDialog, GotoDialog, SnackBar, LiveViewDialog, DetectionClipDialog, SmallButton },
  props: {
    mapviewAlertOn: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    mapviewAlertOn(next) {
      if (next) {
        this.showEditEventTypeDialog = false
        this.dialogGoto = false
        this.showDetectionClipDialog = false
        this.showLiveViewDialog = false
      }
    },
    filteredRecentLogList(newList, oldList) {
      // if log is already selected and fire location/direction OR weather info is "loading"
      if (!!this.selectedLogInfo) {
        const dataPending =
          this.selectedLogInfo.latitude === null ||
          this.selectedLogInfo.longitude === null ||
          this.selectedLogInfo.pan_direction === null ||
          this.selectedLogInfo.temperature === '' ||
          this.selectedLogInfo.wind_speed === '' ||
          this.selectedLogInfo.wind_direction === ''
        // this.selectedLogInfo.relative_humidity === '' ||
        // this.selectedLogInfo.station_name === ''
        let logIdMap = {}
        newList.forEach((log) => (logIdMap[log.log_id] = log))

        if (!!logIdMap[this.selectedLogInfo.log_id]) {
          const updatedLog = newList.filter((item) => item.log_id === this.selectedLogInfo.log_id)[0]

          const newDataFound =
            updatedLog?.latitude !== null &&
            updatedLog?.longitude !== null &&
            updatedLog?.pan_direction !== null &&
            updatedLog?.temperature !== '' &&
            updatedLog?.wind_speed !== '' &&
            updatedLog?.wind_direction !== ''
          // updatedLog.relative_humidity !== '' &&
          // updatedLog.station_name !== ''

          const eventTypeIdChanged = updatedLog?.event_type_id !== this.selectedLogInfo.event_type_id
          const detectionTypeChanged =
            JSON.stringify(updatedLog?.response_status) !== JSON.stringify(this.selectedLogInfo.response_status) ||
            Util.isDuplicateLog(updatedLog) !== Util.isDuplicateLog(this.selectedLogInfo)

          if ((dataPending && newDataFound) || eventTypeIdChanged || detectionTypeChanged) {
            this.$store.dispatch('setMapViewSelectedLogAction', updatedLog)
          }
        }
      }
    },
  },
  data() {
    return {
      // {
      //   "camera_id": 4,
      //   "camera_name": "HeavenlySkiArea",
      //   "log_id": 112906,
      //   "offset": "-7",
      //   "detected_image_path": "develop/4/2022/07/11/1956.jpg",
      //   "before_image_paths": [],
      //   "after_image_paths": [],
      //   "objects": [],
      //   "created_at": "2022-07-11T05:30:48Z",
      //   "event_type_id": 1,
      //   "direction": null,
      //   "temperature": "60.8F",
      //   "wind_speed": "0 mph",
      //   "wind_direction": "N",
      //   "station_name": "South Lake Tahoe, Lake Tahoe Airport"
      // }
      // clipboard variables
      clipboard: false,
      clipboardColor: '',
      clipboardMsg: '',
      // dialog variables
      dialogGoto: false,
      showEditEventTypeDialog: false,
      showLiveViewDialog: false,
      showDetectionClipDialog: false,
      // accordion variables
      logAccordionLoadingTimeout: null,
      isLogAccordionLoading: false,
      repetitiveLogsList: [],
      // weather info variables
      weatherInfo: [
        {
          label: 'Temperature',
          key: 'temperature',
          testId: 'MapView_Temperature_Label',
        },
        {
          label: 'Wind Speed',
          key: 'wind_speed',
          testId: 'MapView_WindSpeed_Label',
        },
        {
          label: 'Wind Direction',
          key: 'wind_direction',
          testId: 'MapView_WindDirection_Label',
        },
        {
          label: 'Relative Humidity',
          key: 'relative_humidity',
          testId: 'RelativeHumidity_Label',
        },
        {
          label: 'Station',
          key: 'station_name',
          testId: 'MapView_WeatherStation_Label',
        },
      ],
    }
  },
  created() {
    this.$store.dispatch('setIsLogListLoadedAction', false)
  },
  destroyed() {
    clearTimeout(this.logAccordionLoadingTimeout)
    this.$store.dispatch('setMapViewSelectedLogAction', null)
  },
  computed: {
    ...mapState({
      isLogListLoaded: (state) => state.isLogListLoaded,
      eventTypeList: (state) => state.eventTypeList,
      recentLogList: (state) => state.recentLogList,
      camMap: (state) => state.camMap,
      selectedLogInfo: (state) => state.mapViewSelectedLog,
      mapViewFilter: (state) => state.mapViewFilter,
      isCustom: (state) => state.mapViewFilter.detectionWithin.isCustom,
      historyLogList: (state) => state.logListv2,
      isViewer: (state) => state.user.isViewer,
      logChildren: (state) => state.logChildren,
      expandedAccordionLogId: (state) => state.mapviewExpandedAccordionLogId,
    }),
    filteredLogChildren() {
      if (this.mapViewFilter.detectionWithin.isCustom) {
        const START_DATE = this.mapViewFilter.detectionWithin.dateObj.startDate.format()
        const END_DATE = this.mapViewFilter.detectionWithin.dateObj.endDate.format()
        return this.logChildren?.[this.expandedAccordionLogId]?.filter((log) =>
          moment(log.created_at, 'YYYY-MM-DDTHH:mm:ssZ').isBetween(START_DATE, END_DATE)
        )
      }
      return this.logChildren[this.expandedAccordionLogId]
    },
    isEventTypeEditDisabled() {
      return this.isViewer || !this.selectedLogInfo || Util.isDuplicateLog(this.selectedLogInfo)
    },
    onlyFirstDetection() {
      return this.mapViewFilter.detectionType.firstDetection
    },
    mapViewDrawerTitle() {
      if (this.isCustom) {
        const dateObj = this.mapViewFilter.detectionWithin.dateObj
        if (dateObj.startDate.isSame(dateObj.endDate, 'day')) return dateObj.endDate.format('YYYY-MM-DD')
        return `${dateObj.startDate.format('YYYY-MM-DD')} ~ ${dateObj.endDate.format('YYYY-MM-DD')}`
      } else return 'Recent'
    },
    filteredRecentLogList() {
      if (this.isCustom && this.historyLogList.length) return [...this.historyLogList.filter((log) => this.logfiltering(log))]
      else return this.recentLogList.filter((log) => this.logfiltering(log))
    },
    selectedLogCameraInfo() {
      if (!this.selectedLogInfo || !this.camMap) {
        return null
      }
      return this.camMap[this.selectedLogInfo.camera_id]
    },
    goToLinks() {
      if (this.selectedLogCameraInfo) {
        return [this.selectedLogCameraInfo.public_url, this.selectedLogCameraInfo.private_url_1, this.selectedLogCameraInfo.private_url_2]
      }
      return []
    },
    eventType() {
      if (!this.selectedLogInfo) return null
      return this.eventTypeList.find((e) => e.id === this.selectedLogInfo.event_type_id)
    },
    eventTypeValue() {
      if (!this.selectedLogInfo) {
        return '-'
      }
      return this.eventType?.id === 1 ? '-' : this.eventType?.name
    },
    eventIdValue() {
      if (!this.selectedLogInfo) {
        return '-'
      }
      return '#' + this.selectedLogInfo.log_id
    },
    detectedTimeValue() {
      if (!this.selectedLogInfo) {
        return '-'
      }
      return Util.getOffsetDate(this.selectedLogInfo.created_at, this.selectedLogInfo.offset)
    },
    fireLocationLabel() {
      if (!this.selectedLogInfo) return 'Fire Location'
      const latitude = this.selectedLogInfo?.latitude
      const longitude = this.selectedLogInfo?.longitude
      const panDirection = this.selectedLogInfo?.pan_direction
      if ((latitude === -1 || !latitude) && (longitude === -1 || !longitude) && panDirection !== -1 && panDirection) return 'Fire Direction'
      else return 'Fire Location'
    },
    fireLocationValue() {
      if (this.selectedLogInfo) {
        const latitude = this.selectedLogInfo?.latitude
        const longitude = this.selectedLogInfo?.longitude
        if (latitude === null && longitude === null) return 'Loading...'
        else if (latitude !== null && latitude !== -1 && longitude !== null && longitude !== -1) return `${latitude} ${longitude.toFixed(4)}`
      }
      return '-'
    },
    fireLocationIsTriangulated() {
      return this.selectedLogInfo?.is_triangulated
    },
    fireDirectionValue() {
      if (this.selectedLogInfo) {
        const pan_direction = this.selectedLogInfo.pan_direction
        if (pan_direction === null) return 'Loading...'
        else if (pan_direction !== -1) return Util.locaitonToDegree(pan_direction)
      }
      return '-'
    },
    cameraLocationValue() {
      if (!this.selectedLogInfo) {
        return '-'
      }
      const coordinates = this.selectedLogCameraInfo.coordinate.split(' ')
      const latitude = Number(coordinates[0]).toFixed(6)
      const longitude = Number(coordinates[1]).toFixed(4)
      return `${latitude} ${longitude}`
    },
  },
  methods: {
    ...mapActions(['getRecentLogList', 'getLogChildrenAction', 'setMapviewExpandedAccordionLogIdAction']),
    showTimeoutIcon(log) {
      const CAM_PAUSE_START_TIMESTAMP = this.camMap[log.camera_id].noti_pause_start
      const CAM_PAUSE_END_TIMESTAMP = this.camMap[log.camera_id].noti_pause_end
      return moment().isBetween(CAM_PAUSE_START_TIMESTAMP, CAM_PAUSE_END_TIMESTAMP)
    },
    onClickLogAccordion(log) {
      this.isLogAccordionLoading = true
      this.setMapviewExpandedAccordionLogIdAction(log.log_id)
      clearTimeout(this.logAccordionLoadingTimeout)
      this.logAccordionLoadingTimeout = setTimeout(async () => {
        await this.getLogChildrenAction(log.log_id)
        this.isLogAccordionLoading = false
      }, 1000)
    },
    onClickCopy(text) {
      try {
        this.clipboardColor = 'secondary'
        this.clipboardMsg = 'Copied to clipboard.'
        navigator.clipboard.writeText(text)
        this.clipboard = true
      } catch (e) {
        this.clipboard = true
        this.clipboardColor = 'error'
        this.clipboardMsg = e.toString()
      }
    },
    logfiltering(log) {
      const necessaryKeys = ['is_checked_organization', 'is_duplicated_by_organization', 'is_duplicated_by_worker']
      const inv2RecentLogList = necessaryKeys.every((k) => Object.keys(log).includes(k))
      const detectionWithin = this.detectionWithinFilter(log, this.mapViewFilter.detectionWithin)
      const responseStatus = this.responseStatusFilter(log, this.mapViewFilter.responseStatus)
      const eventType = this.eventTypeFilter(log, this.mapViewFilter.eventType)
      if (inv2RecentLogList && detectionWithin && responseStatus && eventType) return true
      else return false
    },
    detectionWithinFilter(log, filter) {
      if (filter.dateObj === null) return true
      const createdTime = moment(log.created_at)
      if (filter.isCustom) return createdTime.isBetween(filter.dateObj.startDate.startOf('day'), filter.dateObj.endDate.endOf('day'))
      else return filter.dateObj.isSameOrBefore(createdTime)
    },
    responseStatusFilter(log, filter) {
      const status = this.getStatus(log)
      if ((status === 'Alerted' && filter.alerted) || (status === 'Validated' && filter.validated) || (status === 'Responded' && filter.responded))
        return true
      else return false
    },
    eventTypeFilter(log, filter) {
      const eventType = log.event_type_id
      if (
        (eventType === 1 && filter.unselected) ||
        (eventType === 2 && filter.fire) ||
        (eventType === 3 && filter.nonFire) ||
        (eventType === 4 && filter.unknown) ||
        (eventType === 5 && filter.plannedBurn)
      ) {
        return true
      } else return false
    },
    stopAlert() {
      this.$emit('stopAlert')
    },
    logTimestampFormat(log) {
      return `[${Util.getOffsetDate(log.created_at, log.offset)}]`
    },
    getStatus(item) {
      if (!item) {
        return ''
      }
      if (Util.isDuplicateLog(item)) {
        return 'Repetitive'
      }
      let result = 'Alerted'
      if (item?.response_status?.is_validated) {
        result = 'Validated'
      }
      if (item?.response_status?.is_responded) {
        result = 'Responded'
      }
      return result
    },
    getStatusClass(item) {
      let result = ''
      if (Util.isDuplicateLog(item)) {
        result = 'repetitive-btn-style'
      } else {
        result = 'alerted-btn-style'
        if (item?.response_status?.is_validated) {
          result = 'validated-btn-style'
        }
        if (item?.response_status?.is_responded) {
          result = 'responded-btn-style'
        }
      }
      if (this.$vuetify.theme.dark) {
        result += '-dark'
      }
      return result
    },
    onClickLogItem(item) {
      this.$store.dispatch('setMapViewSelectedLogParentAction', item.log_id)
      if (item.log_id !== this.expandedAccordionLogId && this.expandedAccordionLogId !== -1) {
        this.setMapviewExpandedAccordionLogIdAction(this.expandedAccordionLogId)
      }
    },
    onClickLogChild(child) {
      this.$store.dispatch('setMapViewSelectedLogChildAction', child.id)
    },
    onClickDetectionInfoClose() {
      this.$emit('stopAlert')
      this.$store.dispatch('setShowMapViewResponseStatusPopoverAction', false)
      this.$store.dispatch('setMapViewSelectedLogAction', null)
      // close expanded log if selected log is first detection and not same as expanded log's id
      if (!this.selectedLogInfo?.root_log_id && this.expandedAccordionLogId !== -1 && this.selectedLogInfo?.log_id !== this.expandedAccordionLogId) {
        this.setMapviewExpandedAccordionLogIdAction(this.expandedAccordionLogId)
      }
    },
    onClickItemStatusBtn() {
      this.$store.dispatch('setShowMapViewResponseStatusPopoverAction', true)
    },
    onClickOption(option) {
      switch (option) {
        case 'live-view':
          this.showLiveViewDialog = true
          break
        case 'detection':
          this.showDetectionClipDialog = true
          break
        case 'go-to':
          this.dialogGoto = true
          break
        case 'share':
          this.onClickLinkShare()
          break
        default:
          break
      }
    },
    onClickLinkShare() {
      try {
        this.clipboardColor = 'secondary'
        this.clipboardMsg = 'Copied to clipboard.'
        Util.copyClipboard(`${location.origin}/detection?name=${this.selectedLogInfo.camera_name}&id=${this.selectedLogInfo.log_id}`)
        this.clipboard = true
      } catch (e) {
        this.clipboard = true
        this.clipboardColor = 'error'
        this.clipboardMsg = e.toString()
      }
    },
    onClickEventTypeEdit() {
      if (this.isEventTypeEditDisabled) return
      this.showEditEventTypeDialog = true
    },
    onSaveEventType(eventTypeId) {
      this.$store.dispatch('setLogEventTypeAction', {
        log_id: this.selectedLogInfo.log_id,
        event_type_id: eventTypeId,
      })
      this.showEditEventTypeDialog = false
      this.getRecentLogList().then((logs) => {
        if (logs && logs.length && logs.filter((log) => log.log_id === this.selectedLogInfo.log_id).length) {
          const updatedLogObject = logs.filter((log) => log.log_id === this.selectedLogInfo.log_id)[0]
          this.$store.dispatch('setMapViewSelectedLogAction', updatedLogObject)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.triangulated-tooltip {
  background-color: #ffffff;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);

  &.dark {
    background-color: #323340;
  }
}

.triangulated-tooltip-text {
  font-weight: 400;
  font-size: 14px;
  color: #ff827c;

  &.dark {
    color: #ffffffde;
  }
}

.triangulated-tooltip-icon {
  margin-left: 7px;
}

.map-select-drawer {
  width: 380px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  background-color: white;
  position: relative;
  &.dark {
    background-color: #25262c;
  }

  .recent-log-list-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;

    .recent-log-list-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      margin-bottom: 10px;
      color: #363636 !important;
      &.dark {
        color: #ffffffde !important;
      }
    }

    .recent-log-list-item-list {
      flex-grow: 1;
      width: 100%;
      margin-bottom: 8px;

      &.selected {
        margin-bottom: 0px;
      }

      .recent-log-list-item {
        height: 73px;
        width: 335px;
        padding: 0px 0px 0px 15px;
        display: flex;
        // justify-content: center;
        align-items: center;
        background-color: #f9f9f9;
        border-radius: 8px;
        cursor: pointer;
        color: var(--f-text-black);

        &.duplicate-alarm-item {
          background-color: #f3f4f4;
          // padding: 0 15px;
          // width: 320px !important;
          margin-bottom: 4px;
          // justify-content: space-between;
          padding-right: 30px;
        }

        &.dark {
          background-color: #323340;
          color: #ffffffde;
        }

        &.duplicate-alarm-item.dark {
          background-color: #1b1c22;
        }

        .recent-log-list-item-info {
          // flex-grow: 1;
          width: 209px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 14px;

          .recent-log-list-item-info-top {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .recent-log-list-item-color-circle {
              height: 10px !important;
              min-width: 10px !important;
              border-radius: 50%;
              margin-right: 7px;
              &.fire {
                background-color: var(--f-primary-90);
                border-width: 0px;
                color: #ffffffde;
              }

              &.non-fire {
                background-color: var(--f-supplementary-green);
                border-width: 0px;
                color: #ffffffde;
              }

              &.unknown {
                background-color: var(--f-supplementary-blue);
                border-width: 0px;
                color: #ffffffde;
              }

              &.planned {
                background-color: var(--f-supplementary-purple);
                border-width: 0px;
                color: #ffffffde;
              }

              &.default {
                background-color: transparent;
                border: 1px solid #d8d9da;
              }

              &.dark {
                border: 1px solid #ffffffde;
              }
            }

            .recent-log-list-item-camera-name {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;
              width: 200px;
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: pre;

              &.dark {
                color: #ffffffde;
              }
            }
          }

          .recent-log-list-item-info-bottom {
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            width: 100%;
            padding-left: 17px;
            color: #8d90a2;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            white-space: nowrap;
            &.dark {
              color: #ffffff99;
            }

            .timeout-icon {
              margin-left: 5px;
            }
          }
        }

        .recent-log-list-item-status {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          width: 70px !important;
          border-radius: 4px;
          color: #1b1c22;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          cursor: pointer;
          background-color: #d8d9da;

          &.repetitive-btn-style {
            background-color: #f3f4f4;
            color: #363636;
            border: 1px solid #d8d9da;
          }
          &.alerted-btn-style {
            background-color: #f9f9f9;
            color: #363636;
            border: 1px solid #d8d9da;
          }

          &.validated-btn-style,
          &.responded-btn-style {
            background-color: #d8d9da;
            color: #363636;
          }

          &.repetitive-btn-style-dark {
            background-color: #1b1c22;
            color: #ffffffde;
            border: 1px solid #ffffffde;
          }
          &.alerted-btn-style-dark {
            background-color: #323340;
            color: #ffffffde;
            border: 1px solid #ffffffde;
          }

          &.validated-btn-style-dark,
          &.responded-btn-style-dark {
            background-color: #ffffff;
            color: #25262c;
          }
        }

        .recent-log-list-item-accordion-expand-icon-area {
          height: 73px;
          // width: 40px;
          padding: 0 15px;
          // margin-left: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }

      .recent-log-list-item-duplicate-alarms-list-loading-container {
        height: 73px;
        width: 324px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 8px;
        margin: 4px 5px;
        background-color: #f2f2f2;

        &.dark {
          background-color: #1b1c22;
        }
      }

      .recent-log-list-item-duplicate-alarms-list-container {
        max-height: 308px;
        margin: 4px 0px 4px 5px;
        overflow-y: auto;
        overflow-x: hidden;

        .empty-log-list-children-container {
          height: 69px;
          border-radius: 8px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 500;
          background-color: #f3f4f4;
          color: #363636;
          margin-bottom: 4px;

          &.dark {
            background-color: #1b1c22;
            color: #ffffff99;
          }
        }
      }
    }
  }

  .detection-info-container {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    flex-direction: column;
    border: 5px solid #ffffff;
    background-color: #ffffff;
    &.dark {
      background-color: #25262c;
      border: 5px solid #25262c;
    }
    &.alarm {
      animation: alarm 1.5s steps(2, jump-none) infinite normal;

      @keyframes alarm {
        0% {
        }

        50% {
          border: 5px solid #ff5b54;
        }
      }
    }

    .detection-info-top {
      height: 130px;
      width: 100%;
      padding: 20px;

      .detection-info-btn-close {
        position: absolute;
        top: 15px;
        right: 15px;
        cursor: pointer;
      }
      .detection-info-title {
        font-weight: 600;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 6px;
        color: #363636;
        &.dark {
          color: #ffffffde;
        }
      }

      .detection-item-info-container {
        height: 69px;
        width: 100%;
        padding: 17px 15px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .recent-log-list-item-info {
          // flex-grow: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding-right: 14px;

          .recent-log-list-item-info-top {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            width: 100%;

            .recent-log-list-item-color-circle {
              height: 10px;
              width: 10px;
              border-radius: 50%;
              margin-right: 7px;
              &.fire {
                background-color: var(--f-primary-90);
                border-width: 0px;
                color: #ffffffde;
              }

              &.non-fire {
                background-color: var(--f-supplementary-green);
                border-width: 0px;
                color: #ffffffde;
              }

              &.unknown {
                background-color: var(--f-supplementary-blue);
                border-width: 0px;
                color: #ffffffde;
              }

              &.planned {
                background-color: var(--f-supplementary-purple);
                border-width: 0px;
                color: #ffffffde;
              }

              &.default {
                background-color: transparent;
                border: 1px solid #d8d9da;
              }

              &.dark {
                border: 1px solid #ffffffde;
              }
            }

            .recent-log-list-item-camera-name {
              font-weight: 600;
              font-size: 14px;
              line-height: 19px;

              &.dark {
                color: #ffffffde;
              }
            }
          }

          .recent-log-list-item-info-bottom {
            white-space: nowrap;
            display: flex;
            font-weight: 500;
            font-size: 14px;
            line-height: 19px;
            width: 100%;
            padding-left: 17px;
            color: #8d90a2;
            &.dark {
              color: #ffffff99;
            }
          }
        }

        .recent-log-list-item-status {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          width: 70px;
          border-radius: 4px;
          color: #1b1c22;
          font-weight: 600;
          font-size: 12px;
          line-height: 20px;
          text-align: center;
          cursor: pointer;
          background-color: #d8d9da;

          &.repetitive-btn-style,
          &.alerted-btn-style {
            background-color: #f9f9f9;
            border: 1px solid #d8d9da;
          }

          &.validated-btn-style,
          &.responded-btn-style {
            background-color: #d8d9da;
          }

          &.repetitive-btn-style-dark,
          &.alerted-btn-style-dark {
            background-color: #323340;
            color: #f3f4f4;
            border: 1px solid #ffffffde;
          }

          &.validated-btn-style-dark,
          &.responded-btn-style-dark {
            background-color: #ffffff;
          }
        }
      }
    }

    .detection-info-middle {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 350px;

      .detection-info-option {
        // width: 25%;
        height: 66px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        padding: 12px 0 12px 0;
        cursor: pointer;
        border-top: 1px solid #f9f9f9;
        border-bottom: 1px solid #f9f9f9;
        &.dark {
          border-top: 1px solid #323340;
          border-bottom: 1px solid #323340;
        }

        .detection-info-option-text {
          font-weight: 500;
          font-size: 11px;
          color: #363636;
          &.dark {
            color: #ffffffde;
          }
        }
      }

      .detection-info-option-spacer-line {
        height: 36px;
        width: 1px;
        background-color: #f9f9f9;
        &.dark {
          background-color: #323340;
        }
      }
    }

    .detection-info-bottom {
      display: flex;
      flex-direction: column;
      padding: 0 15px;
      .detection-detailed-info-top {
        // height: 182px;
        display: flex;
        flex-direction: column;
        // justify-content: space-between;
        align-items: center;
        padding: 20px 0;
        border-bottom: 1px solid #f9f9f9;
        &.dark {
          border-bottom: 1px solid #323340;
        }
        .detection-detailed-info-row {
          width: 100%;
          display: flex;
          align-items: center;
          margin-bottom: 14px;
          .detection-detailed-info-label-text {
            width: 124px;
            // padding-left: 38px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #8d90a2;
            user-select: text;
            &.dark {
              color: #ffffff99;
            }
          }

          .detection-detailed-info-value-text {
            display: flex;
            align-items: center;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
            display: flex;
            user-select: text;

            &.text-no-drag {
              user-select: none;
            }
            &.dark {
              color: #ffffffde;
            }

            .detection-detailed-info-event-type-btn-edit {
              display: flex;
              justify-content: center;
              align-items: center;

              &.event-type-editable {
                cursor: pointer;
              }
              .detection-detailed-info-event-type-btn-edit-text {
                font-weight: 500;
                font-size: 12px;
                line-height: 18px;
                color: #ff827c;
                margin-left: 6px;
                &.viewer {
                  color: #8d90a2;
                }
              }
            }

            .recent-log-list-item-color-circle {
              height: 10px !important;
              width: 10px !important;
              border-radius: 50%;
              margin-right: 7px;
              &.fire {
                background-color: var(--f-primary-90);
                border-width: 0px;
                color: #ffffffde;
              }

              &.non-fire {
                background-color: var(--f-supplementary-green);
                border-width: 0px;
                color: #ffffffde;
              }

              &.unknown {
                background-color: var(--f-supplementary-blue);
                border-width: 0px;
                color: #ffffffde;
              }

              &.planned {
                background-color: var(--f-supplementary-purple);
                border-width: 0px;
                color: #ffffffde;
              }

              &.default {
                background-color: transparent;
                border: 1px solid #d8d9da;
              }

              &.dark {
                border: 1px solid #ffffffde;
              }
            }
          }
        }
      }

      .detection-detailed-info-bottom {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        .detection-detailed-info-row {
          width: 100%;
          display: flex;
          align-items: flex-start;
          margin-bottom: 14px;

          .detection-detailed-info-label-text {
            width: 124px;
            min-width: 124px;
            // padding-left: 38px;
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            color: #8d90a2;
            user-select: text;
            &.dark {
              color: #ffffff99;
            }
          }

          .detection-detailed-info-value-text {
            display: flex;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #363636;
            user-select: text;
            &.dark {
              color: #ffffffde;
            }
          }
        }
      }
    }
  }
}

.detection-stop-alert {
  display: flex;
  justify-content: center;

  button.normal {
    background-color: #ff5b54 !important;

    &.dark {
      color: #ffffffde;
    }
  }
}

.no-data {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--f-text-black);
  &.dark {
    color: #ffffffde;
  }
  .title {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .content {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
}

.recent-log-list-item-camera-name,
.recent-log-list-item-info-bottom {
  user-select: text;
}
</style>
